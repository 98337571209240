@import "../../styles";
@import "./form";

.form-field-dropdown-wrapper {
  position: relative;

  .form-field-dropdown-button {
    @include border;
    @include text-default;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    color: var(--color-text-light-2);
    background-color: var(--color-background-light-0);
    padding: var(--spacing-xxxs) var(--spacing-xs);
    border-radius: $util-border-radius;
    overflow: hidden;
    white-space: nowrap;

    &.selected {
      color: var(--color-text-light-0);
    }

    &.open {
      border-color: var(--color-brand-0);
    }

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:focus-visible {
      outline: 1px solid var(--color-brand-0);
    }
  }

  .form-field-dropdown-icon {
    margin-left: auto;
    transition-duration: $util-transition-default;

    &.open {
      transform: rotate(180deg);
    }
  }

  .form-field-dropdown-menu {
    @include border;
    @include text-default;
    position: absolute;
    width: 100%;
    border-radius: $util-border-radius;
    color: var(--color-text-light-2);
    background-color: var(--color-background-light-0);
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-duration: $util-transition-fast;
    z-index: 1;

    &.open {
      visibility: visible;
      opacity: 1;
    }
  }

  .form-field-dropdown-ul {
    list-style-type: none;
    margin: 0;
    padding: var(--spacing-xxs) 0;
  }

  .form-field-dropdown-li {
    @include text-sm;
    color: var(--color-text-light-0);
    padding: var(--spacing-xxs) var(--spacing-sm);

    &:hover {
      cursor: pointer;
      background-color: var(--color-interactive-light-hover);

      .form-field-custom-checkbox {
        background-color: var(--color-interactive-light-hover);
      }
    }

    .form-field-browser-checkbox:checked ~ .form-field-custom-checkbox {
      background-color: var(--color-brand-0);
    }
  }

  .form-field-custom-checkbox {
    border-color: var(--color-line-light);
    background-color: var(--color-background-light-0);
  }

  .other-input {
    @include text-sm;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
  }
}