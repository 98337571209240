@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "../css/palette.scss";

.cat-word-group-pronunciation-container {
  margin-bottom: 1rem;
  position: relative;

  .cat-word-group-pronunciation {
    background-color: var(--background-color-darker);
    border-color: var(--border-color);
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.375rem;
  }

  .suggestion-box-container {
    z-index: 99;
    width: 30rem;
    position: fixed;
  }

  .autosuggest {
    box-shadow: var(--box-shadow);
    background-color: var(--background-color);

    .card-body {
      display: flex;
      justify-content: space-between;
    }

    button.ipa-btn {
      border: none;
      color: var(--font-color);
      width: 3rem;
      padding: 0.25rem;

      &:hover {
        color: white;
      }
    }
  }

  span {
    margin-right: 0.125rem;
    padding: 0.1rem;
    font-weight: normal;
    font-size: 1rem;

    &.badge {
      padding-right: 0;

      div {
        outline: 0 solid transparent;
        display: inline-block;
        padding-left: 0.4rem;
      }

      &.bg-secondary {
        background-color: #c9c9c9 !important;
        color: #000;
      }
    }

    .badge-btn {
      z-index: 2;
      cursor: pointer;
      border: none;
      box-shadow: none;
      transition: none;
      margin: -0.1rem;
      margin-left: 0.4rem;
      padding: 0.35rem 0.5rem;

      &.btn-secondary {
        background-color: #c9c9c9;
        color: #000;

        &:hover {
          background-color: #b9b9b9;
        }
      }
    }

    &.new-pronunciation {
      color: $pv-secondary;
    }
  }

  .ctrl-btn {
    border: none;
    box-shadow: none;
    min-width: 2rem;
    font-size: 1rem;
    padding: 0.25rem;
    background-color: transparent;

    &:hover {
      background-color: var(--hover-color);
    }
  }

  .reset-btn-wrapper {
    margin-left: auto !important;
    margin-right: 0;
  }

  .input-error {
    font-size: 80%;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    color: $pv-danger;

    ul {
      margin-bottom: 0;
    }
  }
}
