@import "../../styles";

.cta-button {
  $chevron-transition-distance: var(--spacing-xxxs);

  @include text-default;
  @include text-font-weight-medium;
  text-decoration: none;
  color: $color-text-dark-theme-0;
  border-radius: $util-border-radius-md;
  border: 1px solid $color-interactive-primary;
  background-color: $color-interactive-primary;
  padding: var(--spacing-xxs) var(--spacing-sm);
  white-space: nowrap;
  display: inline-block;
  min-width: fit-content;
  transition: color $util-transition-default,
  border-color $util-transition-default,
  background-color $util-transition-default;

  .cta-button-chevron {
    margin-left: var(--spacing-xs);
    transition-duration: $util-transition-default;
  }

  &:disabled {
    @include disabled;
  }

  &:hover:not(:disabled) {
    cursor: pointer;
    color: $color-text-dark-theme-0;
    border-color: $color-interactive-primary-hover;
    background-color: $color-interactive-primary-hover;

    .cta-button-chevron {
      transform: translateX(#{$chevron-transition-distance});
    }
  }

  &--full-width {
    width: 100%;
    text-align: center;
  }

  &--reverse {
    .cta-button-chevron {
      margin-left: 0;
      margin-right: var(--spacing-xs);
    }

    &:hover:not(:disabled) {
      .cta-button-chevron {
        transform: translateX(calc(#{$chevron-transition-distance} * -1));
      }
    }
  }

  &--outline-blue {
    $btn-color: $color-interactive-primary;
    color: $btn-color;
    border-color: $btn-color;
    background-color: transparent;

    &:hover:not(:disabled) {
      color: $btn-color;
      border-color: $btn-color;
      background-color: var(--color-interactive-hover);
    }
  }

  &--outline-light {
    $btn-color: $color-text-dark-theme-0;
    color: $btn-color;
    border-color: $btn-color;
    background-color: transparent;

    &:hover:not(:disabled) {
      color: $btn-color;
      border-color: $btn-color;
      background-color: var(--color-interactive-hover);
    }
  }

  &--outline-dark {
    $btn-color: $color-text-light-theme-0;
    color: $btn-color;
    border-color: $btn-color;
    background-color: transparent;

    &:hover:not(:disabled) {
      color: $btn-color;
      border-color: $btn-color;
      background-color: var(--color-interactive-hover);
    }
  }

  &--secondary {
    $btn-color: var(--color-text-0);
    color: $btn-color;
    border-color: var(--color-line);
    background-color: var(--color-background-0);

    &:hover:not(:disabled) {
      color: $btn-color;
      border-color: var(--color-line);
      background-color: var(--color-interactive-hover);
    }
  }
}
