@import "../../styles";

.form-element-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-scalable-lg);

  &.no-margin {
    margin-bottom: var(--spacing-xxxs);
  }

  .form-field-text-input,
  .form-field-textarea,
  .form-field-dropdown-button {
    outline: 1px solid transparent;
  }

  &.invalid {
    .form-field-text-input,
    .form-field-textarea,
    .form-field-dropdown-button {
      outline-color: var(--color-form-error);
    }
  }

  .form-element-wrapper-label {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .form-element-wrapper-label-text {
    @include text-font-weight-medium;
    margin-bottom: var(--spacing-xxxxs);
  }

  .form-element-wrapper-nested-component {
    margin-top: var(--spacing-xxxs);
  }

  .form-element-wrapper-helper-text,
  .form-element-wrapper-invalid-text {
    @include text-xs;
    margin-top: var(--spacing-xxxs);
  }

  .form-element-wrapper-helper-text {
    color: var(--color-text-2);
  }

  .form-element-wrapper-invalid-text {
    color: var(--color-form-error);
  }
}
