@import "../css/components.scss";
@import "../css/palette.scss";

.rhn-home {
  .rhn-container {
    max-width: 60rem;
  }

  .table-placeholder {
    border: 1px solid var(--border-color);
    border-radius: $pv-border-radius-md;
    background-color: var(--background-color-darker);
  }

  .new-context-span {
    color: $pv-primary;

    &:hover {
      color: $pv-primary-dark;
      cursor: pointer;
    }
  }

  .rhn-delete-message {
    overflow-wrap: break-word;
  }
}
