@import "../../css/palette";
@import "../../css/components";

.splittable_page {
  flex-grow: 1;
  height: 100%;
  flex-basis: 100%;
}

.page_container {
  padding: 1rem;
  overflow-y: auto;
  height: calc(100vh
  - #{$navbar-height}
  - #{$toolbar-height}
  - #{$tab-height}
  - #{$tabs-scrollbar-height}
  );

  // Firefox scrollbar does not take up any space
  @-moz-document url-prefix() {
    height: calc(100vh
    - #{$navbar-height}
    - #{$toolbar-height}
    - #{$tab-height}
    );
  }
}

.page_container_inner {
  min-width: 20rem;
}

.single_page_container {
  height: 100%;
}