@import "../web-ui/src/styles";

.limits_info_card {
  @include border;
  flex-basis: 100%;
  border-radius: $util-border-radius-md;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-sm);
  overflow: hidden;
  position: relative;
}

.body_container {
  padding: var(--spacing-sm) var(--spacing-md) 0;
}

.title {
  @include text-default;
  @include text-font-weight-medium;
  color: var(--color-text-light-1);
  z-index: 1;
  position: relative;
}

.count {
  color: var(--color-brand-0);
  z-index: 1;
  position: relative;
}

.content {
  margin-bottom: var(--spacing-sm);
  z-index: 1;
  position: relative;
}

.bar {
  position: absolute;
  height: 100%;
  margin-top: auto;
  background-color: var(--color-background-light-1);
}

.limits_info_card.is_free_plan {
  border-color: var(--color-line-light);

  .count {
    color: var(--color-text-light-1);
  }

  &.is_unavailable {
    @include disabled;
  }
}
