@import "../css/palette.scss";

#ppn-mic {
  text-align: center;
  padding-top: var(--spacing-xs);

  .mic {
    width: 7rem;
    height: 7rem;
    border-radius: 5rem;
  }

  img.hero-microphone-icon {
    width: 5rem;
  }

  .react-select-col {
    text-align: left;
    color: var(--font-color);

    .keyword-select__control {
      transition: none;
      background-color: var(--background-color);
      border-color: var(--border-color);

      &:focus {
        border-color: $pv-primary;
      }
    }

    .keyword-select__indicator-separator {
      background-color: var(--border-color);
    }

    .keyword-select__menu {
      background-color: var(--background-color);

      .keyword-select__option--is-focused {
        background-color: var(--hover-color);
      }
    }

    .keyword-select__input {
      color: var(--font-color) !important;
    }

    .keyword-select__multi-value {
      background-color: var(--hover-color);

      .keyword-select__multi-value__label {
        color: var(--font-color);
      }
    }
  }

  .keyword-select__value-container {
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
