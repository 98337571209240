@import "../web-ui/src/styles";

.access_key_info_container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.engine_row_container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  .engine_info_container {
    height: 7.5rem;
  }

  @include breakpoint-up($breakpoint-sm) {
    flex-direction: row;
  }
}

.engine_info_container {
  @include border;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  overflow-y: auto;
  border-radius: $util-border-radius-md;
  background-color: var(--color-background-0);
  height: 4.75rem;
}

.top_label {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include breakpoint-up($breakpoint-lg) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
}

.engine,
.usage {
  @include text-sm;
}

.engine {
  @include text-font-weight-medium;
}

.usage {
  color: var(--color-text-2);
}
