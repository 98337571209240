@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../css/palette";
@import "../css/components";

.highlights-input-container {
  position: relative;
  overflow-y: visible;
  display: inline-block;
  width: 100%;
}

.highlights-input-control {
  background-color: var(--background-color);
}

.highlights-input-renderer {
  width: 100%;
  border: none;
  padding: 9px;
  position: relative;
  overflow-wrap: break-word;
  box-sizing: border-box;
  text-align: start;
  white-space: pre-wrap;

  .custom-placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}

.highlights-input-textarea {
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  bottom: 0;
  height: 100%;
  margin: 0;
  resize: none;
  display: block;
  padding: 9px;
  position: absolute;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  background: transparent;
  -webkit-text-fill-color: transparent;
}

.highlights-input-textarea,
.highlights-input-renderer {
  color: var(--font-color);
  display: block;
  min-height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  background-clip: padding-box;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    outline: 0;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }
}

.new {
  .highlights-input-renderer {
    border: 1px solid transparent;
  }

  .highlights-input-textarea {
    border: 1px solid var(--border-color);

    // Customize the `:focus` state to imitate Bootstrap form input styles.
    &:focus:not(&.error) {
      border-color: $pv-primary;
      box-shadow: $form-field-box-shadow;
    }

    &.error {
      border-color: $bootstrap-danger;

      &:focus {
        box-shadow: $form-field-box-shadow-danger;
      }
    }
  }

  .highlights-input-textarea,
  .highlights-input-renderer {
    border-radius: $input-border-radius;
    padding: $input-padding-y 2rem $input-padding-y $input-padding-x;
  }
}
