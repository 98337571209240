@import "../css/palette.scss";
@import "../css/components.scss";

.confirmation-code-container {
  height: 100vh;
  background-color: $pv-offwhite;
}

.confirmation-code {
  max-width: 25rem;
  border: 1px solid $pv-line;
  background-color: $pv-white;
  border-radius: $pv-border-radius-md;

  .form-control {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

input.form-control.confirmation-input {
  border: 1px solid $pv-line;
  background-color: $pv-white;
}
