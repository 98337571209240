@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "./css/palette.scss";

.notification-centre {
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  z-index: 999999;
  max-width: calc(100% - 2rem);
  @media only screen and (max-width: 768px) {
    bottom: 1rem;
  }
}

.notification {
  box-shadow: var(--box-shadow);
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  width: 20rem;
  animation: fade-in 0.5s;
  max-width: 100%;

  &.notification-important {
    @include media-breakpoint-up(md) {
      width: 32rem;
    }
  }
}

.notification-image {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0.1rem 0.3rem;
}

.toast-header {
  background-color: $pv-primary;

  strong {
    margin-left: 0.3rem;
    margin-right: auto;
    color: white;
  }

  &.notification-success {
    background-color: $pv-success;
  }

  &.notification-error {
    background-color: $pv-danger;
  }

  &.notification-warning {
    background-color: $pv-warning;
  }
}

.toast-header.light {
  border-bottom: 1px solid $pv-white;
}

.toast-body.light {
  color: $pv-text;
  background-color: $pv-white;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
