@import "../web-ui/src/styles";

.video_embed {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: $util-border-radius-md;
}

.caption {
  @include text-sm;
  @include text-font-weight-medium;
  margin: var(--spacing-xxxs) var(--spacing-sm);
  display: flex;
}

.icon {
  margin: var(--spacing-xxxs) var(--spacing-sm) 0 0;
}
