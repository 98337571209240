@import "../css/palette";
@import "../css/components";

.search_input {
  width: 16rem;
  color: var(--font-color);
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  padding-left: 2.5rem;
  background: url(/svg/icons8-magnifier.svg) no-repeat scroll 0.5rem 0.5rem;
  transition: width $pv-transition-duration-1;

  &:focus {
    width: 20rem;
  }

  &:focus-visible {
    outline: none;
  }
}

[data-theme="dark"] {
  .search_input {
    background: url(/svg/icons8-magnifier_white.svg) no-repeat scroll 0.5rem 0.5rem;

    &::placeholder {
      color: $pv-dark-theme-text-darker;
    }
  }
}