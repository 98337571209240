@import "../css/palette.scss";
@import "../css/components.scss";

.oz-input {
  .input-wrapper.extant {
    border-left: 0.125rem solid transparent;

    &:hover:not(.disabled) {
      background-color: var(--hover-color);

      input {
        background-color: transparent;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: $pv-disabled-opacity;
    }

    &:has(input:focus) {
      border-left: 0.125rem solid $pv-primary;
    }

    &:has(input.is-invalid) {
      border-left: 0.125rem solid $bootstrap-danger;
    }
  }

  button.mini-action {
    width: 2.225rem;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  .input-error {
    font-size: 0.8rem;
    color: $pv-danger;

    ul {
      padding-bottom: 0.25rem;
      margin-bottom: 0;
    }
  }

  .new {
    .input-error {
      ul {
        margin-top: 0.5rem;
        padding-bottom: 0;
      }
    }
  }
}
