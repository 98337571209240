@import "../css/palette.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.main_container {
  width: 25rem;
}

.spinner {
  margin-right: 0.75rem;
}

.form_helper_text {
  display: block;
  min-height: 1.5rem;
  color: var(--font-color-alt);
}

.helper_text {
  min-height: 1.5rem;
}

:global(.tooltip-inner) {
  max-width: unset;
}

.hr {
  opacity: 1;
  background-color: var(--border-color);
}

.download_button {
  width: 100%;
  display: flex;

  &:focus {
    background-image: none;
    box-shadow: none;
  }
}
