@import "../css/components";
@import "../css/palette";
@import "../web-ui/src/styles/components";

$docs-table-row-min-height: 2.625rem;
$docs-table-row-gap: var(--spacing-xs);

.docs_table {
  border-radius: $pv-border-radius-md;
  border: 1px solid var(--border-color);
  background-color: var(--background-color-darker);
  padding: var(--spacing-md) var(--spacing-xxxxs) var(--spacing-md) var(--spacing-md);
}

.loading_container {
  width: 1.5rem;
  height: 1.5rem;
}

.top_row {
  margin-left: calc(1rem + 1px);
  margin-right: calc(2.75rem + 1px);
}

.rows {
  max-height: calc(10 * ($docs-table-row-min-height + $docs-table-row-gap) - $docs-table-row-gap);
  overflow-y: scroll;
  scrollbar-gutter: stable;
  display: flex;
  flex-direction: column;
  gap: $docs-table-row-gap;
}

.row {
  display: flex;
  align-items: center;
  min-height: $docs-table-row-min-height;
  padding-right: 0.5rem;
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &.selected {
    background-color: var(--hover-color);
  }

  &.disabled {
    opacity: $pv-disabled-opacity;
  }

  &:hover {
    background-color: var(--hover-color);

    &:not(.disabled) {
      .options_button {
        visibility: initial;
      }
    }
  }
}

.link {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: var(--font-color);

  &:hover {
    color: var(--font-color);
  }
}

.display_text {
  max-width: 24ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.options_button {
  @include center-child;
  visibility: hidden;
  border: none;
  aspect-ratio: 1;
  background-color: transparent;
  border-radius: $pv-border-radius;

  &:hover {
    background-color: var(--hover-color);
  }
}

.options_button_active {
  visibility: visible;
  background-color: var(--background-color-darker);
}

.icon {
  width: 1rem;
  height: 1rem;
  fill: var(--font-color);

  :global(.icon-fill) {
    fill: var(--font-color);
  }
}

.options {
  position: fixed;
  padding: 0.5rem;
  z-index: 998;
  max-width: fit-content;
  border-radius: $pv-border-radius-md;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow-secondary);

  .icon {
    margin-right: 0.5rem;
  }

  button:not(:last-child) {
    margin-bottom: 0.25rem;
  }
}

.option_button {
  border: none;
  color: var(--font-color);
  background: transparent;
  border-radius: $pv-border-radius;
  text-align: left;
  padding: 0.25rem 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: var(--hover-color);
  }
}

.rename_input {
  color: var(--font-color);
  height: calc(1.5rem - 1px);
  background-color: var(--background-color);
  border-radius: $pv-border-radius;
  border: 1px solid $pv-primary;
  box-shadow: $form-field-box-shadow;

  &:focus-visible {
    outline: none;
  }
}
