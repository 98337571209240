@import "../web-ui/src/styles";

.recent_doc {
  @include border;
  padding: var(--spacing-md);
  border-radius: $util-border-radius-md;

  &:hover {
    background-color: var(--color-interactive-hover);
  }
}

.label_container {
  display: flex;
  justify-content: space-between;
}

.language,
.date {
  @include text-sm;
  color: var(--color-text-2);
}

.language {
  background-color: var(--color-background-1);
  padding: 0 var(--spacing-xs);
  border-radius: $util-border-radius;
}

.date {
  @include text-sm;
  color: var(--color-text-2);
  margin-top: var(--spacing-xxs);
}
