@import "../web-ui/src/styles";

.account_box {
  @include border;
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  border-radius: $util-border-radius-md;
  width: 100%;

  @include breakpoint-up($breakpoint-md) {
    width: 50%;
  }

  @include breakpoint-up($breakpoint-xl) {
    width: 25%;
  }
}

.delete_account {
  margin-top: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  border-top: 1px solid var(--color-line);
}

.delete_account_helper_text {
  @include text-xs;
  color: var(--color-text-2);
  margin-bottom: 0;
}
