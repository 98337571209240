$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin breakpoint-up($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content
  }
}