@import "../css/palette.scss";
@import "../css/components.scss";
@import "../web-ui/src/styles/components";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$body-height: calc(100vh - $navbar-height - $toolbar-height);

.cat-lm {
  .lm-error {
    text-align: center;
    margin: 2rem;
  }

  .validating-wrapper {
    cursor: wait;
  }

  .validating {
    pointer-events: none;
  }

  .btn-control {
    &.disable {
      pointer-events: none;
    }
  }

  .main-container {
    height: $body-height;
    overflow-y: auto;
  }

  #left {
    width: 100%;
    position: absolute;
    height: $body-height;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;

    .cat-error-badge {
      color: $pv-white;
      bottom: 0.125rem;
      margin-left: 1rem;
      background-color: $pv-danger;
    }
  }

  .sidebar-tab {
    @include sidebar-element;
    white-space: nowrap;
    margin: 0 0.5rem;

    &.disabled {
      opacity: $pv-disabled-opacity;
    }

    &.active {
      border-color: var(--active-color);
      background-color: var(--active-color);

      &.error {
        background-color: $bootstrap-danger-opaque;
      }
    }

    &.error {
      border: 1px solid $pv-danger;
    }
  }

  #right {
    height: $body-height;
  }
}
