@import "../../css/palette";
@import "../../css/components";

$resizer-width: 12px;
$resizer-horizontal-height: 8px;
$ellipsis-vertical-width: 4px;

.split-pane-ellipsis-vertical,
.split-pane-toggle-button {
  height: 100%;
  position: absolute;
  transform: translate(-100%, 0);
}

.split-pane-ellipsis-vertical {
  width: $ellipsis-vertical-width;
  padding: 0 calc(($resizer-width - $ellipsis-vertical-width) / 2);
}

.split-pane-toggle-button {
  z-index: 2;
  padding: 0;
  border: none;
  width: $resizer-width;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--background-color-darker);

  svg {
    max-width: 100%;
  }

  svg path {
    fill: var(--font-color-alt);
  }

  &:hover {
    background-color: var(--hover-color-alt);
  }
}

.split-pane {
  .Pane.vertical.Pane1 {
    overflow-y: auto;
  }

  .Resizer {
    position: relative;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer.horizontal {
    margin: calc(-#{$resizer-horizontal-height} / 2) 0;
    border-top: calc(#{$resizer-horizontal-height} / 2) solid transparent;
    border-bottom: calc(#{$resizer-horizontal-height} / 2) solid transparent;
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top-color: var(--background-color-darker);
  }

  .Resizer.vertical {
    min-width: $resizer-width;
    cursor: col-resize;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }

  .Resizer.vertical:active {
    background-color: var(--hover-color);
  }
}