@import "../css/palette";

.icon_white {
  display: none;
}

[data-theme="dark"] {
  .icon {
    display: none;
  }

  .icon_white {
    display: block;
  }
}