@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "../css/palette.scss";

.rhn-context-item {
  .input-group {
    .btn-sm {
      width: 2rem;
      align-self: flex-start;
    }
  }

  .input-wrapper {
    font-family: $font-family-monospace;

    &.extant {
      border-left: 0.125rem solid transparent;

      &:has(input:focus) {
        border-left: 0.125rem solid $pv-primary;
      }

      &:has(input.is-invalid) {
        border-left: 0.125rem solid $bootstrap-danger;
      }

      &:hover {
        background-color: var(--hover-color-solid);

        input {
          background-color: var(--hover-color-solid);
        }
      }
    }
  }

  .input-error ul {
    padding-bottom: 0.25rem;
    margin-bottom: 0;
  }

  .list-wrapper {
    border-bottom: 1px solid var(--border-color);
  }
}
