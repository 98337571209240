@import "../../styles";

.company-logos-carousel {
  display: flex;
  flex-direction: column;

  .label {
    @include text-xs;
    @include text-font-weight-medium;
    text-transform: uppercase;
  }

  &:not(&.label-stacked) {
    @include breakpoint-up($breakpoint-lg) {
      flex-direction: row;
      align-items: center;
    }

    .label {
      @include breakpoint-up($breakpoint-lg) {
        display: flex;
        flex-direction: column;
        margin-right: var(--spacing-scalable-xxxl);
      }
    }
  }

  .gradient {
    position: absolute;
    width: 100px;
    height: 100%;
    z-index: 1;
  }

  .gradient-left {
    background: linear-gradient(90deg, var(--color-background-0), #FFFFFF00)
  }

  .gradient-right {
    right: 0;
    background: linear-gradient(90deg, #FFFFFF00, var(--color-background-0))
  }

  .logos-container {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  .logos-animate-container {
    min-width: 4300px;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
    display: flex;
    animation: scroll 40s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  .svg-container {
    margin: 0 var(--space-md);

    svg {
      height: 2rem;
      max-width: 6rem;
    }

    .sm-svg {
      height: 3rem;
      max-width: 8rem;
    }
  }
}
