@import "../../styles";

@mixin form-field-text-element {
  @include border;
  @include text-default;
  color: var(--color-text-light-0);
  background-color: var(--color-background-light-0);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  border-radius: $util-border-radius;

  &:focus,
  &:focus-visible {
    outline: 1px solid var(--color-brand-0);
  }
}

@mixin form-field-select-element($browser-input-el, $custom-input-el) {
  $custom-input-el-width: 1rem;
  display: block;
  position: relative;
  padding-left: calc(#{$custom-input-el-width} + var(--spacing-xs));
  margin-bottom: var(--spacing-xxxxs);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  #{$browser-input-el} {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  #{$custom-input-el} {
    @include border;
    position: absolute;
    left: 0;
    top: 0.2rem;
    width: $custom-input-el-width;
    height: $custom-input-el-width;
    background-color: var(--color-background-0);
    outline: 1px solid transparent;
  }

  #{$browser-input-el}:focus ~ #{$custom-input-el} {
    outline-color: var(--color-brand-0);
  }

  &:hover:not(.disabled) #{$custom-input-el} {
    background-color: var(--color-interactive-hover);
  }

  #{$browser-input-el}:checked ~ #{$custom-input-el} {
    background-color: var(--color-brand-0);
  }

  #{$custom-input-el}::after {
    content: "";
    position: absolute;
    display: none;
  }

  #{$browser-input-el}:checked ~ #{$custom-input-el}::after {
    display: block;
  }

  #{$custom-input-el}::after {
    border: solid white;
  }
}

.form {
  @include text-sm;
  color: var(--color-text-1);

  .cta-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .form-half-width-elements-container {
    @include breakpoint-up($breakpoint-lg) {
      display: flex;
      gap: var(--spacing-md);

      .form-element-wrapper {
        width: calc(50% - #{var(--spacing-md)} / 2);
      }
    }
  }
}
