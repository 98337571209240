:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;

  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}