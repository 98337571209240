@import "./css/palette.scss";
@import "./css/_oz_table.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "./web-ui/dist/esm/entry.css";
@import "./web-ui/src/font_face";

.main {
  .create-new {
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    margin-top: 2rem;
    padding: 1rem;

    .draft {
      min-width: 10rem;
      float: right;
    }
  }

  .refresh.loading {
    animation: rotation 1s infinite linear;
  }

  ol.breadcrumb {
    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }

    border-radius: 0 0 5px 5px;
  }
}

.navbar {
  @include media-breakpoint-up(md) {
    .nav-link {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    .nav-link {
      &:hover {
        color: white;
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  background-color: var(--border-color);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
  inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

div.oz-rtl-input, div.oz-ltr-input {
  width: 100%;
}

.oz-rtl-input {
  .form-control {
    direction: rtl;
  }

  &.form-control {
    direction: rtl;
  }

  textarea, .highlights-input-renderer {
    direction: rtl;
  }
}

.oz-ltr-input {
  .form-control {
    direction: ltr;
  }

  &.form-control {
    direction: ltr;
  }

  textarea, .highlights-input-renderer {
    direction: ltr;
  }
}

.modal-dialog-bottom-pad {
  padding-bottom: 15rem;
}
