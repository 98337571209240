// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";

@import "./palette.scss";

$font-family-base: "Poppins", sans-serif;
$h3-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;

.popover {
  border: 1px solid var(--border-color);
}

$enable-gradients: true;
$enable-shadows: true;

$input-box-shadow: null;
$input-focus-box-shadow: null;
$input-btn-focus-box-shadow: null;
$input-focus-border-color: $pv-primary;
