@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "../css/palette.scss";

.toggle-btn {
  font-size: 0.8rem;
  padding: 0.3rem;
  border: 1px solid transparent;
  background: none;
  display: flex;
  align-items: center;
  z-index: 99;
  border-radius: $pv-border-radius;

  &:hover {
    background-color: $pv-light-theme-hover;
  }
}