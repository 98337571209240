@import "../../styles";
@import "./form";

.form-field-radio-button {
  @include form-field-select-element(
    ".form-field-browser-radio-button",
    ".form-field-custom-radio-button"
  );

  .form-field-custom-radio-button {
    border-radius: 50%;
  }

  .form-field-custom-radio-button::after {
    left: calc(#{4 / 16} * 1rem);
    top: calc(#{4 / 16} * 1rem);
    width: calc(#{2 / 16} * 1rem);
    height: calc(#{2 / 16} * 1rem);
    border-radius: 50%;
    background: white;
  }
}