@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "../css/palette.scss";
@import "../css/components.scss";

.confirmation-code-container {
  height: 100vh;
  background-color: $pv-offwhite;

  .resend-confirmation-email {
    color: $pv-primary;

    &:hover {
      cursor: pointer;
      color: $pv-primary-dark;
    }
  }
}

.confirmation-code-box {
  background-color: $pv-white;
  border: 1px solid $pv-line;
  border-radius: $pv-border-radius-md;
  max-width: 23rem;

  @include media-breakpoint-up(md) {
    max-width: unset;
    width: 25rem;
  }
}
