@import "../css/palette";
@import "../css/components";

.card_button {
  color: var(--font-color);
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.wide {
  min-width: 16rem;
}