@import "../css/palette.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.shepherd-element {
  max-width: 17rem;

  .shepherd-text {
    font-size: 12pt;
  }

  .shepherd-footer {
    padding-top: .5rem;
  }

  .control-svg {
    fill: white;
    width: 0.75rem;
    height: 0.75rem;

    &.back {
      transform: rotate(180deg);
    }
  }

  &.tour-intro {
    max-width: 31rem;
    text-align: center;
    padding: 1rem;

    .shepherd-text {
      font-size: 16pt;

      h2 {
        margin-bottom: 1.25rem;
      }
    }

    .shepherd-footer {
      display: block;
      margin-bottom: .75rem;
    }

    .shepherd-header {
      .shepherd-cancel-icon {
        position: absolute;
        right: 1.25rem;
      }
    }
  }

  .shepherd-button {
    @extend .btn-primary !optional;
    background: $pv-primary;

    &:hover {
      background: #2f6ad9;
      color: $pv-white;
    }

    &.btn-secondary {
      background: $pv-secondary;
      margin-right: auto;

      &:hover {
        background: #5c636a;
      }
    }
  }
}
