@import "../../styles";

.dropdown {
  position: relative;

  @mixin padding {
    padding: var(--spacing-xxs) var(--spacing-sm);
  }

  .label {
    @include padding;
    @include text-default;
    background-color: transparent;
    border: none;
    border-radius: $util-border-radius;
    color: var(--color-text-1);

    &.open,
    &:hover {
      cursor: pointer;
      background-color: var(--color-interactive-hover);
    }

    &.light {
      color: var(--color-text-dark-1);

      &.open,
      &:hover {
        background-color: var(--color-interactive-light-hover);
      }
    }
  }

  .chevron-icon {
    margin-left: var(--spacing-xxxs);
    transition-duration: $util-transition-default;

    &.open {
      transform: rotate(180deg);
    }
  }

  .menu {
    @include border;
    @include box-shadow;
    position: absolute;
    min-width: 100%;
    border-radius: $util-border-radius;
    background-color: var(--color-background-0);
    z-index: 1000;

    &.reversed {
      right: 0;
    }
  }

  .links-list {
    margin: 0;
    padding: var(--spacing-xxs);
  }

  .links-list-item {
    width: 100%;
    list-style-type: none;
    border-radius: $util-border-radius;

    &:hover {
      cursor: pointer;
      background-color: var(--color-interactive-hover);
    }

    &:hover button {
      cursor: pointer;
    }

    a,
    button {
      @include padding;
      @include text-default;
      border: none;
      background: none;
      text-decoration: none;
      text-align: left;
      height: 100%;
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      color: var(--color-text-1);
    }
  }
}
