@import "../../css/palette.scss";

.tab_button {
  border: none;
  color: var(--font-color);
  background-color: transparent;
  border-bottom: 2px solid transparent;
  border-right: 1px solid var(--border-color);

  &:focus-visible {
    outline: none;
  }
}

.tab_button_active {
  border-bottom: 2px solid $pv-primary;
}