@import "../web-ui/src/styles";

.clickwrap-stage {
  margin-top: var(--spacing-scalable-xxxxxl);
  max-width: $breakpoint-md;

  .card-header {
    @include text-h4;
    text-align: center;
    background-color: var(--color-background-1);
  }

  .card-body {
    padding: 0;
  }

  .card-main-content {
    padding: var(--spacing-md);
  }

  .buttons-row {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    margin-top: var(--spacing-scalable-lg);
  }

  .control-button {
    flex-basis: 100%;
    text-align: center;
  }
}
