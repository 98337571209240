@import "../web-ui/src/styles";

.access_key_copy {
  @include text-sm;
  font-family: monospace;
  border-radius: $util-border-radius;
  display: flex;
}

.access_key,
.copy_button {
  padding: var(--spacing-xxxs) var(--spacing-xs);
}

.access_key {
  @include border;
  flex-basis: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
  border-top-left-radius: $util-border-radius;
  border-bottom-left-radius: $util-border-radius;
  background-color: var(--color-background-1);

  &:hover {
    cursor: default;
  }
}

.copy_button {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  background-color: var(--color-interactive-primary);
  color: var(--color-text-dark-0);
  border: 1px solid var(--color-interactive-primary);;
  border-top-right-radius: $util-border-radius;
  border-bottom-right-radius: $util-border-radius;

  &:disabled {
    @include disabled;
  }

  &:not(&:disabled) {
    &:hover {
      cursor: pointer;
      border: 1px solid var(--color-interactive-primary-hover);
      background-color: var(--color-interactive-primary-hover);
    }
  }
}
