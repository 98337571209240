@import "../../css/components";

.tabs_container {
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: $tabs-scrollbar-height;
    border-top: 1px solid var(--border-color);
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: $pv-border-radius-md;
  }

  // Firefox scrollbar does not have a border
  @-moz-document url-prefix() {
    border-bottom: 1px solid var(--border-color);
  }
}