@import "../web-ui/src/styles";

$breakpoint: $breakpoint-lg;

.signup {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include breakpoint-up($breakpoint) {
    flex-direction: row;
  }

  .left-container,
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--spacing-scalable-lg);
  }

  .left-container {
    @include breakpoint-up($breakpoint) {
      min-width: 60%;
    }
  }

  .right-container {
    flex-grow: 1;
    border-top: 1px solid var(--color-line-light);
    background-color: var(--color-background-light-1);

    @include breakpoint-up($breakpoint) {
      border-top: none;
      border-left: 1px solid var(--color-line-light);
      min-height: 100vh;
      align-items: flex-start;
    }
  }

  .left-content-container,
  .right-content-container {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-scalable-xxxxxl) 0 var(--spacing-scalable-lg);
    flex-grow: 1;
    width: 100%;
    max-width: 42rem;
  }

  .right-content-container-card {
    @include border;
    padding: var(--spacing-scalable-lg);
    border-radius: $util-border-radius-md;
    background-color: var(--color-background-light-0);
    min-height: 30rem;
    display: flex;
    flex-direction: column;

    @include breakpoint-up($breakpoint) {
      max-width: 30rem;
    }
  }

  .limits-info-cards-container {
    width: 100%;
    margin-top: var(--spacing-md);
  }

  .limits-info-cards-row-container {
    @include breakpoint-up($breakpoint-md) {
      display: flex;
      gap: var(--spacing-sm);
    }
  }

  .features-list-container {
    flex-basis: 100%;
  }

  .hr {
    opacity: 1;
    background-color: var(--border-color);
  }

  .logos-banner-container {
    max-width: 100%;
    margin-top: auto;
  }

  .logo-container {
    margin-left: calc(#{var(--spacing-scalable-lg)} + 1px);
    padding-bottom: var(--spacing-scalable-xl);
  }

  .developer-plan-trial-switch {
    @include text-sm;
    display: flex;
    gap: var(--spacing-xxxs);

    .form-check-input {
      box-shadow: none;
      border-color: var(--color-line-light);

      &:checked {
        background-color: var(--color-brand-0);
        border-color: var(--color-brand-0);
      }
    }
  }

  // Text
  .signup-h1 {
    @include text-h3;
    @include text-font-weight-medium;
  }

  .signup-h2 {
    @include text-default-lg;
    @include text-font-weight-medium;

  }

  .signup-text-sm {
    @include text-sm;
  }

  .signup-text-xs {
    @include text-xs;
  }

  .signup-text-light {
    color: var(--color-text-2);
  }

  // Form
  .form-control {
    @include border;
    color: var(--color-text-light-0);
    background-color: var(--color-background-light-0);

    &:focus {
      color: var(--color-text-light-0);
      background-color: var(--color-background-light-0);
    }

    &.valid {
      border-color: var(--color-form-success);
    }

    &.is-invalid {
      background-image: none;
    }
  }

  .inactive {
    @include disabled;
  }

  .eye-icon-btn {
    z-index: 4;
    border: none;
    top: 0.375rem;
    right: 0.375rem;
    background-color: transparent;

    .fa-eye,
    .fa-eye-slash {
      * {
        fill: var(--color-text-light-2);
      }

      opacity: 0.5;

      &:hover {
        opacity: unset;
      }
    }

    .fa-eye {
      padding-right: 1px;
    }
  }

  .password-hint-box {
    @include text-sm;
    @include border;
    margin-top: var(--spacing-xxxs);
    padding: var(--spacing-md);
    border-color: var(--color-line-light);
    color: var(--color-text-light-2);
    border-radius: $util-border-radius;

    &.valid {
      border-color: var(--color-form-success);
    }

    .valid {
      list-style-type: "✔  ";
      color: var(--color-form-success);
    }
  }

  .feedback {
    font-size: 0.8rem;

    &.valid {
      color: var(--color-form-success);
    }
  }
}
