// Brand
$color-brand-0: #377dff;
$color-brand-1: #ff3d81;
$color-brand-2: #7429a9;

// Black & White
$color-white: #fff;
$color-black: #000;

// Background
$color-bg-light-theme-0: #ffffff;
$color-bg-light-theme-1: #f1f3f4;
$color-bg-dark-theme-0: #1e2022;
$color-bg-dark-theme-1: #181a1c;

// Line
$color-line-light-theme: #dee2e7;
$color-line-dark-theme: #3c4043;

// Text
$color-text-light-theme-0: $color-bg-dark-theme-1;
$color-text-light-theme-1: $color-bg-dark-theme-0;
$color-text-light-theme-2: #666666;
$color-text-dark-theme-0: $color-bg-light-theme-0;
$color-text-dark-theme-1: $color-bg-light-theme-1;
$color-text-dark-theme-2: #9e9e9e;

// Links
$color-link-light-theme: #276def;
$color-link-dark-theme: #3c82ff;

// Interactive
$color-interactive-light-theme-hover: #dfdfdf55;
$color-interactive-light-theme-active: #e1f5fe;
$color-interactive-dark-theme-hover: #42444688;
$color-interactive-dark-theme-active: #377dff33;

// Primary button
$color-interactive-primary: $color-brand-0;
$color-interactive-primary-hover: #2e69d6;
$color-interactive-primary-active: #0a58ca;

// Mic button
$color-mic-button-box-shadow: #377dff99;
$color-mic-button-box-shadow-light: #ddecfaaa;

// Forms
$color-form-error: #dc3545;
$color-form-success: $color-brand-0;

// Demos
$color-demo-light-theme: #000000;
$color-demo-dark-theme: #ffffff;

// Misc
$color-misc-variant-0-light: #adeac32f;
$color-misc-variant-1-light: #a09cf322;
$color-misc-variant-2-light: #e7c7bb2f;
$color-misc-variant-3-light: #90dad92f;
$color-misc-variant-0-dark: #04802f;
$color-misc-variant-1-dark: #804CF5;
$color-misc-variant-2-dark: #FF6B37;
$color-misc-variant-3-dark: #0aaab9;

:root {
  // Static colors
  --color-brand-0: #{$color-brand-0};
  --color-brand-1: #{$color-brand-1};
  --color-brand-2: #{$color-brand-2};
  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-line-light: #{$color-line-light-theme};
  --color-line-dark: #{$color-line-dark-theme};
  --color-background-light-0: #{$color-bg-light-theme-0};
  --color-background-light-1: #{$color-bg-light-theme-1};
  --color-background-dark-0: #{$color-bg-dark-theme-0};
  --color-background-dark-1: #{$color-bg-dark-theme-1};
  --color-text-light-0: #{$color-text-light-theme-0};
  --color-text-light-1: #{$color-text-light-theme-1};
  --color-text-light-2: #{$color-text-light-theme-2};
  --color-text-dark-0: #{$color-text-dark-theme-0};
  --color-text-dark-1: #{$color-text-dark-theme-1};
  --color-text-dark-2: #{$color-text-dark-theme-2};
  --color-interactive-light-hover: #{$color-interactive-light-theme-hover};
  --color-interactive-light-active: #{$color-interactive-light-theme-active};
  --color-interactive-dark-hover: #{$color-interactive-dark-theme-hover};
  --color-interactive-dark-active: #{$color-interactive-dark-theme-active};
  --color-interactive-primary: #{$color-interactive-primary};
  --color-interactive-primary-hover: #{$color-interactive-primary-hover};
  --color-interactive-primary-active: #{$color-interactive-primary-active};
  --color-form-error: #{$color-form-error};
  --color-form-success: #{$color-form-success};
  --color-misc-variant-0-light: #{$color-misc-variant-0-light};
  --color-misc-variant-1-light: #{$color-misc-variant-1-light};
  --color-misc-variant-2-light: #{$color-misc-variant-2-light};
  --color-misc-variant-3-light: #{$color-misc-variant-3-light};
  --color-misc-variant-0-dark: #{$color-misc-variant-0-dark};
  --color-misc-variant-1-dark: #{$color-misc-variant-1-dark};
  --color-misc-variant-2-dark: #{$color-misc-variant-2-dark};
  --color-misc-variant-3-dark: #{$color-misc-variant-3-dark};
  --color-demo-light: #{$color-demo-light-theme};
  --color-demo-dark: #{$color-demo-dark-theme};
  --color-mic-button-box-shadow: #{$color-mic-button-box-shadow};
  --color-mic-button-box-shadow-light: #{$color-mic-button-box-shadow-light};

  // Themed colors
  --color-background-0: #{$color-bg-light-theme-0};
  --color-background-1: #{$color-bg-light-theme-1};
  --color-text-0: #{$color-text-light-theme-0};
  --color-text-1: #{$color-text-light-theme-1};
  --color-text-2: #{$color-text-light-theme-2};
  --color-link: #{$color-link-light-theme};
  --color-interactive-hover: #{$color-interactive-light-theme-hover};
  --color-interactive-active: #{$color-interactive-light-theme-active};
  --color-line: #{$color-line-light-theme};
  --color-demo: #{$color-demo-light-theme};
}

.dark-mode-section,
.dark-mode .docs,
[data-theme="dark"] {
  // Themed colors
  --color-background-0: #{$color-bg-dark-theme-0};
  --color-background-1: #{$color-bg-dark-theme-1};
  --color-text-0: #{$color-text-dark-theme-0};
  --color-text-1: #{$color-text-dark-theme-1};
  --color-text-2: #{$color-text-dark-theme-2};
  --color-link: #{$color-link-dark-theme};
  --color-interactive-hover: #{$color-interactive-dark-theme-hover};
  --color-interactive-active: #{$color-interactive-dark-theme-active};
  --color-line: #{$color-line-dark-theme};
  --color-demo: #{$color-demo-dark-theme};
}
