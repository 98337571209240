@import "../css/palette";
@import "../css/components";

.button_container {
  &:hover {
    .button {
      display: inline-block;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: $pv-disabled-opacity;
  }
}

.oz_input_list_item {
  display: flex;
  align-items: center;
  height: 3rem;
  border-top: 1px solid var(--border-color);

  &:hover {
    .button {
      display: inline-block;
    }

    :global(.form-control).input {
      background-color: transparent;
    }
  }
}

:global(.form-control).input {
  margin-right: auto;
  margin-left: 0.5rem;
  border: none;
  box-shadow: none;
  background-image: none;

  &:focus {
    cursor: initial;
    box-shadow: none;

    & ~ .button {
      display: inline-block;
    }
  }
}

.button_wrapper {
  margin-right: 0.5rem;
  width: 2rem;
}

.button {
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: $pv-border-radius;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: var(--hover-color);
  }
}

.toggle_modal_button {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  border-radius: 50%;

  &:not(:disabled):hover {
    background-color: var(--hover-color);
  }

  :global(svg path) {
    fill: var(--font-color);
  }
}

.svg {
  fill: var(--font-color);
  width: 100%;
  height: 100%;
}

.green {
  fill: $pv-success;
}

.red {
  fill: $pv-danger;
}