@import "../css/palette";

.dropdown_toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0ms;
  color: var(--font-color);
  border-color: var(--border-color);
  background-color: var(--background-color);

  &.dropdown_toggle_initial {
    color: var(--font-color-alt);
  }

  &:disabled,
  &:hover,
  &:active,
  &:active:focus {
    &:not(.dropdown_toggle_initial) {
      color: var(--font-color);
    }

    box-shadow: none;
    border-color: var(--border-color);
    background-color: var(--background-color);
  }

  &:focus,
  &:focus:hover {
    &:not(.dropdown_toggle_initial) {
      color: var(--font-color);
    }

    box-shadow: $bs-form-field-box-shadow;
    border-color: $pv-primary;
    background-color: var(--background-color);
  }
}

.dropdown_menu {
  width: 100%;

  :global(.dropdown-item) {
    background-image: none;
  }
}