@import "../../styles";

.multi-language {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xxxs);

  .tile {
    @include text-default;
    @include border;
    flex-grow: 1;
    color: var(--color-text-1);
    background-color: var(--color-background-1);
    border-radius: $util-border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-xxxs) var(--spacing-xs);

    .lang-native {
      @include text-xs;
      color: var(--color-text-2);
    }

    &.beta {
      @include disabled;
    }
  }

  &::after {
    content: "";
    flex-grow: 100;
  }
}