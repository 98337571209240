@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "../css/palette.scss";

.rhn-suggestion-list {
  font-size: 0.8rem;

  ul {
    list-style: none;
    padding: 0.2rem;
    margin: 0;
  }

  li {
    border: 1px solid var(--border-color);
    padding: 0.5rem;

    &.rhn-suggestion-focused {
      background-color: $primary;
      color: $pv-dark-theme-text;
    }
  }

  li:first-child {
    border-radius: $input-border-radius $input-border-radius 0 0;
  }

  li:last-child {
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }

  li:only-child {
    border-radius: $input-border-radius;
  }
}
