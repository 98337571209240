@import "../../css/palette";
@import "../../css/components";

.toolbar {
  height: $toolbar-height;
  border-bottom: 1px solid var(--border-color);
  background: var(--background-color);
  position: relative;
}

.toolbar_button {
  border: none;
  border-radius: $pv-border-radius;
  background-color: transparent;

  &:disabled {
    opacity: $pv-disabled-opacity;
    pointer-events: none;
  }

  &:hover:not(:disabled) {
    background-color: var(--hover-color);
  }
}

.error_msg {
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 1.5rem;
  border-radius: 0.25rem;
  color: $bootstrap-danger;
}

.error_msg_btn {
  padding: 0;
  border: none;
  font-weight: bold;
  color: $bootstrap-danger;
  text-decoration: underline;
  background-color: transparent;
}

.vertical_line {
  height: 100%;
  width: 1px;
  margin: 0 0.25rem;
  background-color: var(--border-color);
}
