@import "../css/palette.scss";
@import "../css/components";

@import "../web-ui/src/styles";

.access_key_info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  align-items: flex-start;

  @include breakpoint-up($breakpoint-lg) {
    flex-direction: row;
  }
}

.access_key_info_item_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.access_key_info_access_key_container {
  @include breakpoint-up($breakpoint-lg) {
    max-width: 50%;
  }
}

.access_key_copy_container {
  @include border;
  padding: var(--spacing-md);
  border-radius: $util-border-radius-md;
}

.access_key_copy_description {
  @include text-sm;
  color: var(--color-text-2);
  margin: var(--spacing-sm) 0 0;
}
