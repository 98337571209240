@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "../css/palette";
@import "../web-ui/src/styles";

.thumbnail_container {
  position: relative;
}

.thumbnail {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1200 / 630;
  border-radius: $util-border-radius-md;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.icon_video {
  position: absolute;
  right: var(--spacing-xxxs);
  bottom: var(--spacing-xxxs);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $text-xs;
  background-color: $pv-dark-opaque;
  border-radius: $util-border-radius;
}

.icon_title {
  color: var(--color-text-0);
  margin-right: var(--spacing-sm);
}

.title_container {
  display: flex;
  margin: var(--spacing-sm) var(--spacing-sm) 0;
}

.title {
  @include text-default;
  margin-bottom: 0;
  color: var(--color-text-0);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
