@import "../../styles";

.audio-file-upload {
  width: 100%;
  height: 100%;
  $expand-rem: 0.5rem;

  .audio-file-upload-drag-and-drop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $expand-rem;
    transition: padding $util-transition-fast;

    &:hover,
    &.dragging {
      padding: 0;
    }
  }

  .audio-file-upload-button {
    @include text-sm;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--color-demo-light);
    border: 2px dashed var(--color-demo-light);
    border-radius: $util-border-radius;
    background-color: transparent;

    &:hover {
      padding: 0;
      cursor: pointer;
    }
  }

  .audio-file-upload-input {
    display: none;
  }

  #svg-download-icon-arrow1,
  #svg-download-icon-arrow2 {
    animation: MoveUpDown 2s ease-in-out infinite;
    @keyframes MoveUpDown {
      0%, 100% {
        transform: translateY(5%);
      }

      50% {
        transform: translateY(-5%);
      }
    }
  }

  &.light {
    .audio-file-upload-button {
      color: var(--color-demo-dark);
      border-color: var(--color-demo-dark);
    }
  }
}