@import "../web-ui/src/styles";

$breakpoint: $breakpoint-lg;
$spacing-default: var(--spacing-md);
$spacing-lg: var(--spacing-scalable-lg);

.second_stage {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.main_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $breakpoint-md;
}

.main_title {
  @include text-h2;
  margin-top: var(--spacing-scalable-xxxl);
  margin-bottom: var(--spacing-sm);
}

.page_container {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-lg;
  border-top: 1px solid var(--color-line);
}

.navigation_container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: var(--spacing-md);
}

.check_items_container {
  @include text-xs;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-sm);
}

.alert {
  color: var(--color-text-1);
  margin-top: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: $util-border-radius;
  background-color: var(--color-background-1);
  visibility: hidden;

  &.visible {
    visibility: unset;
  }
}

.hr {
  opacity: 1;
  background-color: var(--border-color);
}
