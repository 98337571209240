.oz-pagination-control {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    justify-content: normal;
    flex-direction: column;
    align-items: flex-end;
  }

  .pagination {
    margin: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }

  .pagination-wrapper {
    display: flex;
    margin-right: .5rem;
    align-items: center;

    .spinner-border {
      margin-right: .5rem;
    }

    .form-control {
      max-width: 4rem;
      text-align: right;
      display: inline;
    }
  }

  .pagination-sm {
    .page-link {
      min-width: 2rem;
      text-align: center;
    }
  }

  .input-group {
    width: auto;
    display: inline-flex;

    .btn {
      min-width: 2rem;
    }
  }
}