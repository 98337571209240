@import "../css/palette.scss";

.oz-yaml-body {
  .input-error {
    font-size: 90%;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;

    ul {
      margin-bottom: 0;

      li {
        color: $pv-danger;
      }
    }
  }
}

.oz-yaml-footer {
  .alert {
    width: 100%;
    padding: 0.5rem 1rem;
  }
}