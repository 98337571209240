@import "../web-ui/src/styles";

$content-item-spacing: var(--spacing-md);
$content-item-num-per-row-sm: 2;
$content-item-num-per-row-lg: 3;
$content-item-num-per-row-xxl: 4;

.featured_content {
  display: flex;
  flex-wrap: wrap;
  gap: $content-item-spacing;
  height: 50vh;
  overflow-y: auto;
  scrollbar-gutter: stable;
  border-radius: $util-border-radius-md;
  padding: $content-item-spacing;
  background-color: var(--color-background-1);
  margin-bottom: var(--spacing-scalable-xxxxl);

  @include breakpoint-up($breakpoint-sm) {
    > .content_item_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-sm} - #{$content-item-spacing} * #{$content-item-num-per-row-sm -
          1} / #{$content-item-num-per-row-sm}
      );
    }
  }

  @include breakpoint-up($breakpoint-lg) {
    > .content_item_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-lg} - #{$content-item-spacing} * #{$content-item-num-per-row-lg -
          1} / #{$content-item-num-per-row-lg}
      );
    }
  }

  @include breakpoint-up($breakpoint-xxl) {
    > .content_item_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-xxl} - #{$content-item-spacing} * #{$content-item-num-per-row-xxl -
          1} / #{$content-item-num-per-row-xxl}
      );
    }
  }
}

.content_item_wrapper {
  margin-bottom: var(--spacing-md);
}

.toolbar {
  margin-bottom: var(--spacing-md);

  @include breakpoint-up($breakpoint-md) {
    display: flex;
    align-items: flex-end;

    :global(.filter-pill) {
      margin-bottom: 0;
    }
  }
}

.intersection_observer {
  height: 1rem;
}
