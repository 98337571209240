@import "../css/palette.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "../web-ui/src/styles";

.auth-n {
  width: 100%;

  .auth-form-container {
    @include border;
    max-width: 28rem;
    margin: var(--spacing-md) auto;
    padding: var(--spacing-scalable-lg);
    border-radius: $util-border-radius-md;
    background-color: var(--color-background-0);
  }

  .auth-title {
    @include text-h3;
    text-align: center;
    color: var(--color-text-0);
  }

  .auth-subtitle {
    @include text-default;
    text-align: center;
    color: var(--color-text-2);
  }

  .auth-form-field {
    margin-bottom: var(--spacing-sm);
  }

  .federated-auth-container {
    margin-top: var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .github-logo,
  .google-logo,
  .linkedin-logo {
    width: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: var(--spacing-xxxxs);
  }

  .google,
  .github,
  .linkedin {
    @include border;
    @include text-sm;
    width: 100%;
    display: flex;
    align-items: center;
    padding: var(--spacing-xs);

    &:hover {
      background-color: var(--color-interactive-hover)
    }
  }

  .federated-auth-text {
    margin-left: var(--spacing-sm);
  }

  .console-type {
    background-color: $pv-primary;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
    display: block;
    font-size: 1.25rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .other-auth-option {
    @include text-xs;
    text-align: center;
    margin-top: var(--spacing-md);
    color: var(--color-text-2);
  }

  .auth-n-box {
    @include border;
    text-align: center;
    border-radius: 0.5rem;
    padding: var(--spacing-md);
    background-color: var(--color-background-0);
    position: fixed;
    top: 5rem;
    left: 50vw;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm) {
      width: 25rem;
    }
  }

  .error-msg {
    display: block;
    text-align: left;
    font-size: 0.75rem;
    max-width: fit-content;
  }
}
