@import "../web-ui/src/styles";

.profile_table {
  margin-top: var(--spacing-md);
  border-radius: $util-border-radius-md;
  border-left: 1px solid var(--color-line);
  border-right: 1px solid var(--color-line);
  border-bottom: 1px solid var(--color-line);

  .table_placeholder {
    border-top: none;
    $row-height: calc(3.5rem + 1px);
    width: 100%;
    height: calc(11 * $row-height);
    border-bottom-left-radius: $util-border-radius-md;
    border-bottom-right-radius: $util-border-radius-md;
  }

  .label_row {
    @include text-font-weight-semibold;
    border-top: 1px solid var(--color-line);
    background-color: var(--color-background-1);
    border-top-left-radius: $util-border-radius-md;
    border-top-right-radius: $util-border-radius-md;
  }

  .row {
    display: flex;
    border-bottom: 1px solid var(--color-line);

    &:last-child {
      border-bottom-left-radius: $util-border-radius-md;
      border-bottom-right-radius: $util-border-radius-md;
      border-bottom: none;
    }
  }

  .label {
    @include text-xs;
    color: var(--color-text-light-2);
  }

  .engine,
  .usage,
  .training_allowance,
  .expiry {
    flex-basis: 25%;
    padding: var(--spacing-md);
    color: var(--color-text-1);
  }
}
