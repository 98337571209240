@import "../css/palette";

.doc_name_display {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.language,
.snapshot {
  color: var(--font-color-alt);
}

.language {
  font-size: 0.7rem;
}

.snapshot {
  min-width: fit-content;
}

.rename_input {
  top: -0.5rem;
  width: 14rem;
  z-index: 1;
}

.rename_input_error_text {
  margin-left: 1rem;
  background-color: var(--background-color);
  border-bottom-right-radius: $pv-border-radius;
  border-bottom-left-radius: $pv-border-radius;
}

.name {
  border: none;
  color: var(--font-color);
  background-color: transparent;
  border-radius: $pv-border-radius;

  &:hover {
    background-color: var(--hover-color);
  }
}

.head_link:hover {
  cursor: pointer;
  text-decoration: underline;
}
