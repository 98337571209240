@import "../../styles";

.filter-pill {
  @include text-xs;
  @include border;
  @include text-font-weight-medium;
  color: var(--color-text-0);
  background-color: transparent;
  border-radius: $util-border-radius;
  padding: var(--spacing-xxxs) var(--spacing-xs);
  margin: 0 var(--spacing-xxs) var(--spacing-xxs) 0;

  &:hover:not(.active) {
    background-color: var(--color-interactive-hover);
  }

  &.active {
    border-color: var(--color-line);
    color: var(--color-text-1);
    background-color: var(--color-line);
  }
}
