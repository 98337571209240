@import "../css/palette.scss";

.or-divider {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1.8rem;
  color: $pv-light-theme-text-lighter;

  span {
    opacity: 0.6;
  }

  &::before,
  &::after {
    content: "";
    display: inline-block;
    border-top: 0.1rem solid $pv-light-theme-line;
    flex-grow: 1;
  }

  &.left-aligned {
    &::before {
      display: none;
    }
  }
}