@import "./palette.scss";

.form_label {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: var(--color-text-2);
}

.helper_text {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--font-color);
}

.has_helper_text {
  height: 5.5rem;
}

.form_input {
  &:global(.form-control::placeholder) { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $pv-font-light-3;
    opacity: 1; /* Firefox */
  }

  &:global(.form-control:-ms-input-placeholder) { /* Internet Explorer 10-11 */
    color: $pv-font-light-3;
  }

  &:global(.form-control::-ms-input-placeholder) { /* Microsoft Edge */
    color: $pv-font-light-3;
  }
}

.is_validating {
  padding-right: calc(1.5em + 0.75rem);;
}
