@import "../../css/palette";
@import "../../css/components";

.tab {
  position: relative;
  width: fit-content;
  max-width: 20rem;
  white-space: nowrap;
  background-color: var(--background-color);
  border-right: 1px solid var(--border-color);
  border-bottom: $tab-border-bottom-width solid transparent;
  padding: 0.5rem 0.5rem calc(0.5rem - $tab-border-bottom-width);
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    border-bottom: $tab-border-bottom-width solid var(--border-color);

    &.error {
      border-bottom: $tab-border-bottom-width solid $bootstrap-danger-opaque;
    }
  }

  &.selected_active {
    border-bottom: $tab-border-bottom-width solid $pv-primary;

    &.error {
      border-bottom: $tab-border-bottom-width solid $bootstrap-danger;
    }
  }
}

.tab_name {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;
}

.italicized {
  font-style: italic;
}

.tab_close_button {
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 50%;
  color: var(--font-color);
  background-color: transparent;

  &:hover {
    background-color: var(--hover-color);
  }
}

.tab_context_menu {
  position: fixed;
  z-index: 1000;
  border: 1px solid var(--border-color);
  border-radius: $pv-border-radius-md;
  padding: 0.5rem;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
}

.tab_context_menu_button {
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: $pv-border-radius;
  color: var(--font-color);
  background-color: transparent;
  padding: 0.25rem 0.5rem;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: var(--hover-color);
  }
}

.tab_context_menu_button_icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.rotate {
  transform: scaleX(-1);
}