@import "../../styles";

.quick-start-link {
  $svg-size: 1.25rem;
  $padding-x: var(--spacing-sm);
  $svg-offset: calc($padding-x + $svg-size);
  @include border;
  @include text-default;
  color: var(--color-text-0);
  border-radius: $util-border-radius;
  padding: var(--spacing-xs) $svg-offset var(--spacing-xs) var(--spacing-sm);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &_icon svg {
    width: $svg-size;
    height: $svg-size;
    margin-right: var(--spacing-md);
  }

  &_arrow {
    position: absolute;
    right: var(--spacing-md);
    transition-property: right;
    transition-duration: $util-transition-fast;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--color-interactive-hover);
  }

  &:hover &_arrow {
    right: var(--spacing-sm);
  }
}
