@import "../css/palette.scss";

.delete_link {
  padding: 0;
  border: none;
  color: var(--color-form-error);
  text-decoration: underline;
  background-color: transparent;

  &:hover {
    opacity: 0.5;
  }
}
