.oz-table {

  .action {
    min-width: 6rem;
  }

  .date {
    width: 7rem;
  }

  button.action {
    min-width: 7rem;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  td {
    border-color: var(--border-color);
  }

  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--font-color);
  margin-bottom: 0;
}

.oz-table > :not(:first-child) {
  border-top: 1px solid var(--border-color)
}

.oz-table.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: var(--font-color);
}
