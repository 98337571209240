.svg-loading-dots {
  circle {
    fill: var(--color-text-light-2);
    stroke: none;
    stroke-width: 1;
    fill-rule: evenodd;
  }

  circle:nth-child(1) {
    animation: load 1s infinite;
  }

  circle:nth-child(2) {
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  circle:nth-child(3) {
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }

  @keyframes load {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
