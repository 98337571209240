@import "../css/palette.scss";

.file_upload_container {
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.file_upload {
  flex-grow: 1;
  position: relative;
  transition: margin 150ms;
  border: 2px dashed var(--border-color);

  svg {
    fill: var(--color-text-1);

    path {
      stroke: var(--color-text-1);
    }
  }

  input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.file_upload_active {
  transition: margin 150ms;
  margin: -1rem;
}

.file_upload_disabled {
  filter: grayscale(100%);
  opacity: 0.5;

  input[type="file"] {
    cursor: default;
  }
}

.mic_helper_text_rec_length {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace;
}

.spinner_container {
  width: 48px;
  height: 48px;
}

:global(#download-icon--animate_arrow1),
:global(#download-icon--animate_arrow2) {
  animation: MoveUpDown 2s ease-in-out infinite;
  @keyframes MoveUpDown {
    0%,
    100% {
      transform: translateY(5%);
    }

    50% {
      transform: translateY(-5%);
    }
  }
}
