@import "../css/palette";

.header {
  padding-left: calc(1rem + 1px);
  padding-right: calc(1.5rem + 1px);
}

.rows {
  height: calc(5 * 3.125rem);
  overflow-y: auto;
}

.button {
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &:hover {
    background-color: var(--hover-color);

    .options_button {
      visibility: visible;
    }
  }
}