@import "node_modules/shepherd.js/dist/css/shepherd";
@import "./palette.scss";
@import "./_custom.scss";

$tooltip-arrow-color: $pv-black;
$tooltip-bg: $pv-black;
$min-contrast-ratio: 3.5;


$theme-colors: (
        "primary": $pv-primary,
        "secondary": $gray-600,
        "success": $pv-success,
        "warning": $pv-warning,
        "info": $pv-info,
        "danger": $pv-danger,
        "light": $white,
        "dark": $black
);

$link-color: $pv-primary;
$link-decoration: none;
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

@import "node_modules/bootstrap/scss/bootstrap";


html {
  min-height: 100%;
}

.svg-button-icon {
  height: 1.5rem;
  width: 1.5rem;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
  border: var(--border)
}

.pv-nav-left {
  margin-left: 1.5rem;
}

.pv-nav-right {
  margin-right: 1.5rem;
}

.modal-content {
  border: none;
  background-color: var(--background-color);

  .modal-footer {
    border-color: var(--border-color);
  }

  .modal-header {
    border-bottom: none;
    background-color: var(--background-color);
    color: var(--font-color);

    .btn-close {
      filter: var(--modal-header-btn-close);
    }
  }
}

input.form-control {
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--font-color);
  transition: none;
  box-shadow: none;

  &:focus {
    background-color: var(--background-color);
    border-color: $pv-primary;
    color: var(--font-color);
  }

  &[disabled] {
    background-color: var(--background-color);
  }
}

textarea.form-control {
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--font-color);
  transition: none;

  &:focus {
    background-color: var(--background-color);
    border-color: $pv-primary;
    color: var(--font-color);
  }

  &[disabled] {
    background-color: var(--background-color);
  }
}

.list-group-item {
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--font-color);

  &.active {
    background-color: var(--background-color);
    border-color: $pv-primary;
    color: var(--font-color);
  }
}

.list-group-item-action:hover {
  background-color: var(--hover-color);
  color: var(--font-color);
}

.list-group-item-action:hover + .list-group-item.active {
  background-color: var(--hover-color);
}

.list-group-item.disabled {
  background-color: var(--background-color);
}

.card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);

  .card-header {
    background-color: var(--background-color-darker);
    border-bottom: 1px solid var(--border-color);
    font-weight: bold;
    text-align: center;
  }

  .card-footer {
    background-color: var(--background-color-darker);
    border-top: 1px solid var(--border-color);
  }
}

.page-link {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  transition: none;

  &:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--border-color);
  }
}

.page-item.disabled .page-link {
  @extend .page-link;
}

.tooltip {
  top: 0;
  pointer-events: none;
}

.oz {
  .pv-logo {
    width: 10rem;
  }

  .dark-mode-toggler {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    @include media-breakpoint-up(md) {
      position: relative;
      top: 0;
      right: 0;
    }
  }

  .navbar {
    background-image: none;
  }

  .navbar-toggler {
    position: absolute;
    top: 0.5rem;
    right: 4rem;
    @include media-breakpoint-up(md) {
      position: relative;
    }
  }

  .nav-item {
    text-align: center;
  }

  .refresh {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 2rem;
  }

  .mic {
    width: 3.1rem;
    height: 2.4rem;
  }

  ul.platform-metrics {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    margin-left: 0.5rem;

    li {
      display: inline-block;
      margin-right: 0.25rem;
    }
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

span.input-group-text {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  color: var(--font-color);
}

.dropdown-menu {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);

  .dropdown-item {
    color: var(--font-color);

    &:hover {
      background-color: var(--hover-color);
    }
  }
}
