@import "../../styles";

.mic-button {
  $padding: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $padding;

  .mic-button-button {
    padding: var(--spacing-xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-line-light);
    border-radius: 100%;
    transition-property: box-shadow;
    transition-duration: 1000ms;
    color: var(--color-mic-button-box-shadow);
    display: flex;

    &.listening {
      transition-duration: $util-transition-fast;

      .svg-mic {
        fill: var(--color-brand-0);
      }
    }

    &:hover:not(:disabled) {
      opacity: 0.8;
      cursor: pointer;
    }

    &:disabled,
    &:disabled:hover {
      @include disabled;

      .helper-text {
        color: var(--color-text-2);
      }
    }
  }

  .helper-text {
    @include text-sm;
    padding: $padding;
    text-align: center;
    color: var(--color-text-0);
    margin-top: var(--spacing-md);
  }

  &.light {
    .mic-button-button {
      color: var(--color-mic-button-box-shadow-light);
    }

    .helper-text {
      color: var(--color-text-dark-0);
    }
  }
}
