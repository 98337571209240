@import "../css/palette.scss";
@import "../css/components.scss";
@import "../web-ui/src/styles/components";

$row-height: $toolbar-height;
$left-offset: 2rem;
$collapse-icon-width: 0.625rem;
$default-padding: 1rem;

.rhn-item-listgroup {
  .rhn-list-group-item-header {
    display: flex;
    align-items: center;
    height: $row-height;
    padding: 0 0.5rem 0 $default-padding;

    &:hover {
      cursor: pointer;
    }
  }

  .rhn-list-group-item-header-collapse {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .collapse-icon {
      margin-right: 0.5rem;
      width: $collapse-icon-width;
      transition-duration: $pv-transition-duration-1;

      &.open {
        transform: rotate(90deg);
      }
    }

    .rhn-error-badge {
      color: $pv-white;
      bottom: 0.125rem;
      margin-left: 1rem;
      background-color: $pv-danger;
    }
  }

  .oz-input-new-button {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    color: var(--font-color);
    background-color: transparent;

    &:disabled {
      opacity: $pv-disabled-opacity;
    }

    &:hover:not(:disabled) {
      background-color: var(--hover-color);
    }
  }

  .new-label.oz-input-new {
    position: fixed;
    z-index: 998;
    width: 20rem;
    padding: $default-padding;
    transform: translateY(-100%);
    border: 1px solid var(--border-color);
    border-radius: $pv-border-radius;
    box-shadow: var(--box-shadow);
    background-color: var(--background-color);

    .oz-input {
      margin: 0;
    }
  }

  .rhn-list-group-item {
    @include sidebar-element;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: none;
    margin: 0 0.5rem 0 $left-offset;

    &:first-child {
      margin-top: 0;
    }

    &.active {
      background-color: var(--active-color);
    }

    &.error {
      border: 1px solid $pv-danger;
    }

    &.active.error {
      background-color: #dc354522;
    }
  }

  .rhn-list-group-item-link-container {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rhn-list-group-item-link {
    padding: 0;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .rhn-list-group-item-text-container {
    max-width: 100%;
    display: flex;
    align-items: center;
  }

  .rhn-item-listgroup-input {
    position: absolute;
    height: 1.75rem;
  }

  .rhn-list-group-item-div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11rem;
  }

  .rhn-list-group-item-error-text {
    margin-top: 0;
    font-size: 0.75rem;
    padding: 0 0 0.25rem $default-padding;
  }
}
