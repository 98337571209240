.right_bottom_pane_container {
  overflow-y: auto;
  border-top: 1px solid var(--border-color);
  background-color: var(--background-color-darker);
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.right_bottom_pane_header {
  font-size: 0.9rem;
  color: var(--font-color-alt);
}

.right_bottom_pane_clear:hover {
  cursor: pointer;
  text-decoration: underline;
}