$text-xs: 0.75rem;
$text-sm: 0.9rem;
$text-md: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-xxl: 1.5rem;
$text-xxxl: 2rem;
$text-xxxxl: 3rem;
$text-xxxxxl: 4rem;
$text-line-height: 1.6;

@mixin font-poppins {
  font-family: 'Poppins', sans-serif;
}

@mixin text-h1-larger {
  @include font-poppins;
  font-weight: bold;
  font-size: $text-xxxl;

  @media screen and (min-width: 992px) {
    font-size: $text-xxxxxl;
  }
}

@mixin text-h1 {
  @include font-poppins;
  font-weight: bold;
  font-size: $text-xxxl;

  @media screen and (min-width: 992px) {
    font-size: $text-xxxxl;
  }
}

@mixin text-h2 {
  @include font-poppins;
  font-weight: bold;
  font-size: $text-xxl;

  @media screen and (min-width: 992px) {
    font-size: $text-xxxl;
  }
}

@mixin text-h3 {
  @include font-poppins;
  @include text-font-weight-medium;
  font-size: $text-xl;

  @media screen and (min-width: 992px) {
    font-size: $text-xxl;
  }
}

@mixin text-h4 {
  @include font-poppins;
  @include text-font-weight-medium;
  font-size: $text-lg;

  @media screen and (min-width: 992px) {
    font-size: $text-xl;
  }
}

@mixin text-default {
  @include font-poppins;
  font-size: $text-md;
  line-height: $text-line-height;
}

@mixin text-default-lg {
  @include font-poppins;
  font-size: $text-lg;
  line-height: $text-line-height;
}

@mixin text-code-markdown {
  font-size: $text-lg;
}

@mixin text-sm {
  @include font-poppins;
  font-size: $text-sm;
  line-height: $text-line-height;
}

@mixin text-xs {
  @include font-poppins;
  font-size: $text-xs;
  line-height: $text-line-height;
}

@mixin text-font-weight-medium {
  font-weight: 500;
}

@mixin text-font-weight-semibold {
  font-weight: 600;
}
