@import "../css/components.scss";
@import "../css/palette.scss";
@import "../web-ui/src/styles";

.picollm-home {
  margin: var(--spacing-scalable-xxxxxl) 0 var(--spacing-scalable-lg);

  $padding-sm: var(--spacing-sm);
  $padding-md: var(--spacing-md);

  .picollm-container {
    max-width: 60rem;
  }

  .picollm-model-families-group {
    margin-top: var(--spacing-xxxs);
    height: 35rem;
    min-width: 30rem;
    overflow: auto;
    position: relative;
    scrollbar-gutter: stable;
  }

  // Family
  .model-family-container {
    @include border;
    width: 100%;
    border: none;
  }

  .model-family-btn {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-background-0);
    color: var(--color-text-0);
    padding: $padding-sm $padding-md;
    border-top: 1px solid var(--color-line);
    position: sticky;
    top: 0;
    z-index: 1;

    &:hover {
      cursor: pointer;
      background-color: var(--color-background-1);
    }

    &.active {
      background-color: var(--color-background-1);
      @include text-font-weight-medium;
    }
  }

  .chevron-icon {
    transition-duration: $util-transition-default;

    &.open {
      transform: rotate(180deg);
    }
  }

  // Models
  .models-header {
    display: flex;
    margin: 0 $padding-md;
    padding: $padding-sm $padding-sm 0;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    z-index: 1;

    &.active {
      position: sticky;
      background-color: var(--color-background-0);
      top: calc(3rem + 1px);
    }
  }

  .models-header-field,
  .models-button-field {
    text-align: left;
    flex-basis: 15%;

    &:first-child {
      flex-basis: 40%;
    }

    &:last-child {
      text-align: center;
    }
  }

  .models-buttons-container {
    display: flex;
    flex-direction: column;
    padding: 0 $padding-sm $padding-sm;
  }

  .model-button {
    color: var(--color-text-1);
    width: 100%;
    border: none;
    display: flex;
    border-radius: $util-border-radius;
    padding: var(--spacing-xs) $padding-md;
    background-color: var(--color-background-0);

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: var(--color-interactive-hover);
    }

    &.selected {
      background-color: var(--color-interactive-active);
    }

    &:disabled {
      @include disabled;
    }
  }

  .model-button-field {
    color: var(--color-text-0);
  }

  .check-icon {
    > svg, > path:first-child {
      fill: var(--color-brand-0);
    }

    > path {
      stroke: var(--color-brand-0);
    }
  }

  .minus-icon {
    > svg, > path:first-child {
      fill: var(--color-text-2);
    }

    > path {
      stroke: var(--color-text-2);
    }
  }

  // Download
  .download-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid var(--color-line);
    padding: $padding-md;
  }

  .model-selected-label,
  .model-selected-info {
    @include text-sm;
  }

  .model-selected-label {
    color: var(--color-text-2);
  }

  .model-selected-info {
    color: var(--color-text-0);
    @include text-font-weight-medium;
  }

  .btn:focus {
    box-shadow: none;
    background-image: none;
  }
}

// Modal
.picollm-download-modal {
  .download-message {
    @include text-sm;
    margin-bottom: var(--spacing-md);
  }

  .download-table-row {
    display: flex;

    &.large {
      align-items: flex-end;

      .download-table-field {
        @include text-h4;
        margin-top: var(--spacing-md);
      }
    }
  }

  .download-table-label,
  .download-table-field {
    @include text-sm;
  }

  .download-table-label {
    flex-basis: 50%;
    color: var(--color-text-2);
  }

  .download-table-field {
    @include text-font-weight-medium;
    color: var(--color-text-0);

    &.danger {
      color: var(--color-form-error);
    }
  }

  .download-table-allowance {
    margin-top: var(--spacing-sm);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-line);
  }

  .btn:focus {
    box-shadow: none;
    background-image: none;
  }
}
