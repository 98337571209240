@import "../css/palette.scss";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$body-height: calc(100vh - $navbar-height - $toolbar-height);

.rhn-editor {
  .validating-wrapper {
    cursor: wait;
  }

  .validating {
    pointer-events: none;
  }

  .updated-status {
    color: var(--font-color);
    font-size: 0.9rem;
  }

  .slot-bubble,
  .macro-bubble,
  .rhn-model-count {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
  }

  .rhn-model-count {
    text-align: center;
    color: white;
    font-size: 1rem;
    background-color: $pv-primary;
  }

  .highlight {
    border-radius: 0.24rem;
  }

  .slot-builtin {
    background-color: var(--highlight-builtin);
  }

  .slot-0 {
    background-color: var(--highlight-0);
  }

  .slot-1 {
    background-color: var(--highlight-1);
  }

  .slot-2 {
    background-color: var(--highlight-2);
  }

  .slot-3 {
    background-color: var(--highlight-3);
  }

  .slot-4 {
    background-color: var(--highlight-4);
  }

  .slot-5 {
    background-color: var(--highlight-5);
  }

  .slot-6 {
    background-color: var(--highlight-6);
  }

  .slot-7 {
    background-color: var(--highlight-7);
  }

  .slot-8 {
    background-color: var(--highlight-8);
  }

  .slot-9 {
    background-color: var(--highlight-9);
  }

  .slot-10 {
    background-color: var(--highlight-10);
  }

  .slot-11 {
    background-color: var(--highlight-11);
  }

  .slot-12 {
    background-color: var(--highlight-12);
  }

  .slot-13 {
    background-color: var(--highlight-13);
  }

  .slot-14 {
    background-color: var(--highlight-14);
  }

  .slot-15 {
    background-color: var(--highlight-15);
  }

  .macro-0 {
    background-color: var(--highlight-15);
  }

  .macro-1 {
    background-color: var(--highlight-14);
  }

  .macro-2 {
    background-color: var(--highlight-13);
  }

  .macro-3 {
    background-color: var(--highlight-12);
  }

  .macro-4 {
    background-color: var(--highlight-11);
  }

  .macro-5 {
    background-color: var(--highlight-10);
  }

  .macro-6 {
    background-color: var(--highlight-9);
  }

  .macro-7 {
    background-color: var(--highlight-8);
  }

  .macro-8 {
    background-color: var(--highlight-7);
  }

  .macro-9 {
    background-color: var(--highlight-6);
  }

  .macro-10 {
    background-color: var(--highlight-5);
  }

  .macro-11 {
    background-color: var(--highlight-4);
  }

  .macro-12 {
    background-color: var(--highlight-3);
  }

  .macro-13 {
    background-color: var(--highlight-2);
  }

  .macro-14 {
    background-color: var(--highlight-1);
  }

  .macro-15 {
    background-color: var(--highlight-0);
  }

  legend {
    width: inherit; /* Or auto */
    padding: 0 10px; /* To give a bit of padding on the left and right */
    border-bottom: none;
    font-size: 1rem;
  }

  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 0 1rem 1rem 1rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  .mic-rhino-output {
    padding: 1rem;
    margin-top: 1rem;
    font-family: $font-family-monospace;
    text-align: left;
  }

  #left {
    width: 100%;
    position: absolute;
    height: $body-height;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  #middle {
    width: 100%;

    .middle-container {
      height: $body-height;
      overflow-y: auto;
    }
  }

  .rhn-mic {
    display: flex;
    height: $body-height;
  }

  .tips-link {
    display: inline-block;
    width: 100%;
    padding: 0.75rem 1rem;
    color: var(--font-color);

    &:hover {
      cursor: pointer;
      background-color: var(--hover-color);
    }

    &.active {
      background-color: var(--active-color);
    }
  }

  .tips-divider {
    margin-bottom: 0;
    opacity: 1;
    background-color: var(--border-color);
  }

  .new-label {
    .oz-input {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .invalid-feedback {
        display: unset;
      }
    }
  }
}
