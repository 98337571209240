@import "../css/palette";
@import "../web-ui/src/styles";

.trial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-scalable-xxxxxl);
  padding-top: var(--spacing-scalable-xxxxxl);
}

.message_container {
  @include border;
  border-radius: $util-border-radius-md;
  padding: var(--spacing-scalable-xxxl);
  text-align: center;
}

.title {
  @include text-h3;
}

.description {
  @include text-h4;
  color: var(--color-text-2);
  margin-top: var(--spacing-xs);
}

.description_small {
  color: var(--color-text-2);
  margin-top: var(--spacing-scalable-lg);
}

.cta_button_container {
  margin-top: var(--spacing-scalable-lg);
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
}
