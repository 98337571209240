@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../css/palette.scss";
@import "../css/components.scss";

.login-container {
  background-color: $pv-offwhite;
}

.login {
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  .forgot-password {
    display: inline-block;
    margin-top: var(--spacing-xxs);
  }

  .forgot-password-link {
    color: $pv-text-lighter;
    text-decoration: underline;
    font-size: 0.75rem;
  }

  .form-control {
    color: $pv-text;
    border: 1px solid $pv-line;
    background-color: unset;

    &:focus {
      color: $pv-text;
      background-color: unset;
    }
  }
}
