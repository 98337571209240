@import "../css/palette.scss";
@import "../web-ui/src/styles";

.progress_bar_placeholder {
  height: 1rem;
}

.progress {
  :global(.progress-bar) {
    background-color: $pv-primary;
  }

  &.warning {
    :global(.progress-bar) {
      background-color: $pv-warning;
    }
  }

  &.danger {
    :global(.progress-bar) {
      background-color: $pv-danger;
    }
  }
}

[data-theme="dark"] {
  .progress {
    background-color: $pv-dark-theme-styled-link-background;
  }
}
