@import "../css/palette.scss";

.cat-download {
  .download-alert {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    font-size: small;
  }

  .quick-start-links-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
}
