@import "../web-ui/src/styles";

.animal_card {
  @include border;
  padding: var(--spacing-md);
  border-radius: $util-border-radius-md;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-1);
  border-color: var(--color-background-1);
}

.animal_card_console {
  border-color: var(--color-line);
  background-color: var(--color-background-0);
}

.animal {
  @include text-sm;
  @include text-font-weight-medium;
  color: var(--color-text-2);
}

.title {
  @include text-h4;
  @include text-font-weight-semibold;
  color: var(--color-text-0);
}

.description {
  @include text-sm;
  color: var(--color-text-2);
  margin: var(--spacing-xxs) 0 var(--spacing-md);
}

.cta {
  width: 100%;
  margin-top: auto;
}
