:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.audio-file-upload {
  width: 100%;
  height: 100%;
}
.audio-file-upload .audio-file-upload-drag-and-drop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  transition: padding 150ms;
}
.audio-file-upload .audio-file-upload-drag-and-drop:hover, .audio-file-upload .audio-file-upload-drag-and-drop.dragging {
  padding: 0;
}
.audio-file-upload .audio-file-upload-button {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--color-demo-light);
  border: 2px dashed var(--color-demo-light);
  border-radius: 0.25rem;
  background-color: transparent;
}
.audio-file-upload .audio-file-upload-button:hover {
  padding: 0;
  cursor: pointer;
}
.audio-file-upload .audio-file-upload-input {
  display: none;
}
.audio-file-upload #svg-download-icon-arrow1,
.audio-file-upload #svg-download-icon-arrow2 {
  animation: MoveUpDown 2s ease-in-out infinite;
}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(5%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.audio-file-upload.light .audio-file-upload-button {
  color: var(--color-demo-dark);
  border-color: var(--color-demo-dark);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.cta-button {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  border-radius: 0.5rem;
  border: 1px solid #377dff;
  background-color: #377dff;
  padding: var(--spacing-xxs) var(--spacing-sm);
  white-space: nowrap;
  display: inline-block;
  min-width: fit-content;
  transition: color 250ms, border-color 250ms, background-color 250ms;
}
.cta-button .cta-button-chevron {
  margin-left: var(--spacing-xs);
  transition-duration: 250ms;
}
.cta-button:disabled {
  opacity: 0.5;
}
.cta-button:hover:not(:disabled) {
  cursor: pointer;
  color: #ffffff;
  border-color: #2e69d6;
  background-color: #2e69d6;
}
.cta-button:hover:not(:disabled) .cta-button-chevron {
  transform: translateX(var(--spacing-xxxs));
}
.cta-button--full-width {
  width: 100%;
  text-align: center;
}
.cta-button--reverse .cta-button-chevron {
  margin-left: 0;
  margin-right: var(--spacing-xs);
}
.cta-button--reverse:hover:not(:disabled) .cta-button-chevron {
  transform: translateX(calc(var(--spacing-xxxs) * -1));
}
.cta-button--outline-blue {
  color: #377dff;
  border-color: #377dff;
  background-color: transparent;
}
.cta-button--outline-blue:hover:not(:disabled) {
  color: #377dff;
  border-color: #377dff;
  background-color: var(--color-interactive-hover);
}
.cta-button--outline-light {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
}
.cta-button--outline-light:hover:not(:disabled) {
  color: #ffffff;
  border-color: #ffffff;
  background-color: var(--color-interactive-hover);
}
.cta-button--outline-dark {
  color: #181a1c;
  border-color: #181a1c;
  background-color: transparent;
}
.cta-button--outline-dark:hover:not(:disabled) {
  color: #181a1c;
  border-color: #181a1c;
  background-color: var(--color-interactive-hover);
}
.cta-button--secondary {
  color: var(--color-text-0);
  border-color: var(--color-line);
  background-color: var(--color-background-0);
}
.cta-button--secondary:hover:not(:disabled) {
  color: var(--color-text-0);
  border-color: var(--color-line);
  background-color: var(--color-interactive-hover);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.dropdown {
  position: relative;
}
.dropdown .label {
  padding: var(--spacing-xxs) var(--spacing-sm);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: transparent;
  border: none;
  border-radius: 0.25rem;
  color: var(--color-text-1);
}
.dropdown .label.open, .dropdown .label:hover {
  cursor: pointer;
  background-color: var(--color-interactive-hover);
}
.dropdown .label.light {
  color: var(--color-text-dark-1);
}
.dropdown .label.light.open, .dropdown .label.light:hover {
  background-color: var(--color-interactive-light-hover);
}
.dropdown .chevron-icon {
  margin-left: var(--spacing-xxxs);
  transition-duration: 250ms;
}
.dropdown .chevron-icon.open {
  transform: rotate(180deg);
}
.dropdown .menu {
  border: 1px solid var(--color-line);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.125);
  position: absolute;
  min-width: 100%;
  border-radius: 0.25rem;
  background-color: var(--color-background-0);
  z-index: 1000;
}
.dropdown .menu.reversed {
  right: 0;
}
.dropdown .links-list {
  margin: 0;
  padding: var(--spacing-xxs);
}
.dropdown .links-list-item {
  width: 100%;
  list-style-type: none;
  border-radius: 0.25rem;
}
.dropdown .links-list-item:hover {
  cursor: pointer;
  background-color: var(--color-interactive-hover);
}
.dropdown .links-list-item:hover button {
  cursor: pointer;
}
.dropdown .links-list-item a,
.dropdown .links-list-item button {
  padding: var(--spacing-xxs) var(--spacing-sm);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  border: none;
  background: none;
  text-decoration: none;
  text-align: left;
  height: 100%;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  color: var(--color-text-1);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.filter-pill {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  line-height: 1.6;
  border: 1px solid var(--color-line);
  font-weight: 500;
  color: var(--color-text-0);
  background-color: transparent;
  border-radius: 0.25rem;
  padding: var(--spacing-xxxs) var(--spacing-xs);
  margin: 0 var(--spacing-xxs) var(--spacing-xxs) 0;
}
.filter-pill:hover:not(.active) {
  background-color: var(--color-interactive-hover);
}
.filter-pill.active {
  border-color: var(--color-line);
  color: var(--color-text-1);
  background-color: var(--color-line);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}

.form-field-text-input {
  border: 1px solid var(--color-line);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-text-light-0);
  background-color: var(--color-background-light-0);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  border-radius: 0.25rem;
}
.form-field-text-input:focus, .form-field-text-input:focus-visible {
  outline: 1px solid var(--color-brand-0);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form-element-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--spacing-scalable-lg);
}
.form-element-wrapper.no-margin {
  margin-bottom: var(--spacing-xxxs);
}
.form-element-wrapper .form-field-text-input,
.form-element-wrapper .form-field-textarea,
.form-element-wrapper .form-field-dropdown-button {
  outline: 1px solid transparent;
}
.form-element-wrapper.invalid .form-field-text-input,
.form-element-wrapper.invalid .form-field-textarea,
.form-element-wrapper.invalid .form-field-dropdown-button {
  outline-color: var(--color-form-error);
}
.form-element-wrapper .form-element-wrapper-label {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-element-wrapper .form-element-wrapper-label-text {
  font-weight: 500;
  margin-bottom: var(--spacing-xxxxs);
}
.form-element-wrapper .form-element-wrapper-nested-component {
  margin-top: var(--spacing-xxxs);
}
.form-element-wrapper .form-element-wrapper-helper-text,
.form-element-wrapper .form-element-wrapper-invalid-text {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  line-height: 1.6;
  margin-top: var(--spacing-xxxs);
}
.form-element-wrapper .form-element-wrapper-helper-text {
  color: var(--color-text-2);
}
.form-element-wrapper .form-element-wrapper-invalid-text {
  color: var(--color-form-error);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}

.form-field-textarea {
  border: 1px solid var(--color-line);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-text-light-0);
  background-color: var(--color-background-light-0);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  border-radius: 0.25rem;
}
.form-field-textarea:focus, .form-field-textarea:focus-visible {
  outline: 1px solid var(--color-brand-0);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}

.form-field-checkbox {
  display: block;
  position: relative;
  padding-left: calc(1rem + var(--spacing-xs));
  margin-bottom: var(--spacing-xxxxs);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-field-checkbox .form-field-browser-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-field-checkbox .form-field-custom-checkbox {
  border: 1px solid var(--color-line);
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-background-0);
  outline: 1px solid transparent;
}
.form-field-checkbox .form-field-browser-checkbox:focus ~ .form-field-custom-checkbox {
  outline-color: var(--color-brand-0);
}
.form-field-checkbox:hover:not(.disabled) .form-field-custom-checkbox {
  background-color: var(--color-interactive-hover);
}
.form-field-checkbox .form-field-browser-checkbox:checked ~ .form-field-custom-checkbox {
  background-color: var(--color-brand-0);
}
.form-field-checkbox .form-field-custom-checkbox::after {
  content: "";
  position: absolute;
  display: none;
}
.form-field-checkbox .form-field-browser-checkbox:checked ~ .form-field-custom-checkbox::after {
  display: block;
}
.form-field-checkbox .form-field-custom-checkbox::after {
  border: solid white;
}
.form-field-checkbox.disabled {
  opacity: 0.5;
}
.form-field-checkbox.disabled:hover {
  cursor: default;
}
.form-field-checkbox .form-field-custom-checkbox {
  border-radius: 0.25rem;
}
.form-field-checkbox .form-field-custom-checkbox::after {
  left: calc(4/16 * 1rem);
  top: calc(1/16 * 1rem);
  width: calc(5/16 * 1rem);
  height: calc(10/16 * 1rem);
  border: solid white;
  border-width: 0 calc(3/16 * 1rem) calc(3/16 * 1rem) 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.form-field-checkbox.margin {
  margin-bottom: var(--spacing-scalable-lg);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}

.form-field-radio-button {
  display: block;
  position: relative;
  padding-left: calc(1rem + var(--spacing-xs));
  margin-bottom: var(--spacing-xxxxs);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-field-radio-button .form-field-browser-radio-button {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-field-radio-button .form-field-custom-radio-button {
  border: 1px solid var(--color-line);
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 1rem;
  height: 1rem;
  background-color: var(--color-background-0);
  outline: 1px solid transparent;
}
.form-field-radio-button .form-field-browser-radio-button:focus ~ .form-field-custom-radio-button {
  outline-color: var(--color-brand-0);
}
.form-field-radio-button:hover:not(.disabled) .form-field-custom-radio-button {
  background-color: var(--color-interactive-hover);
}
.form-field-radio-button .form-field-browser-radio-button:checked ~ .form-field-custom-radio-button {
  background-color: var(--color-brand-0);
}
.form-field-radio-button .form-field-custom-radio-button::after {
  content: "";
  position: absolute;
  display: none;
}
.form-field-radio-button .form-field-browser-radio-button:checked ~ .form-field-custom-radio-button::after {
  display: block;
}
.form-field-radio-button .form-field-custom-radio-button::after {
  border: solid white;
}
.form-field-radio-button .form-field-custom-radio-button {
  border-radius: 50%;
}
.form-field-radio-button .form-field-custom-radio-button::after {
  left: calc(4/16 * 1rem);
  top: calc(4/16 * 1rem);
  width: calc(2/16 * 1rem);
  height: calc(2/16 * 1rem);
  border-radius: 50%;
  background: white;
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.form {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-1);
}
.form .cta-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .form .form-half-width-elements-container {
    display: flex;
    gap: var(--spacing-md);
  }
  .form .form-half-width-elements-container .form-element-wrapper {
    width: calc(50% - var(--spacing-md) / 2);
  }
}

.form-field-dropdown-wrapper {
  position: relative;
}
.form-field-dropdown-wrapper .form-field-dropdown-button {
  border: 1px solid var(--color-line);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: var(--color-text-light-2);
  background-color: var(--color-background-light-0);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
}
.form-field-dropdown-wrapper .form-field-dropdown-button.selected {
  color: var(--color-text-light-0);
}
.form-field-dropdown-wrapper .form-field-dropdown-button.open {
  border-color: var(--color-brand-0);
}
.form-field-dropdown-wrapper .form-field-dropdown-button:hover {
  cursor: pointer;
}
.form-field-dropdown-wrapper .form-field-dropdown-button:focus, .form-field-dropdown-wrapper .form-field-dropdown-button:focus-visible {
  outline: 1px solid var(--color-brand-0);
}
.form-field-dropdown-wrapper .form-field-dropdown-icon {
  margin-left: auto;
  transition-duration: 250ms;
}
.form-field-dropdown-wrapper .form-field-dropdown-icon.open {
  transform: rotate(180deg);
}
.form-field-dropdown-wrapper .form-field-dropdown-menu {
  border: 1px solid var(--color-line);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  position: absolute;
  width: 100%;
  border-radius: 0.25rem;
  color: var(--color-text-light-2);
  background-color: var(--color-background-light-0);
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-duration: 150ms;
  z-index: 1;
}
.form-field-dropdown-wrapper .form-field-dropdown-menu.open {
  visibility: visible;
  opacity: 1;
}
.form-field-dropdown-wrapper .form-field-dropdown-ul {
  list-style-type: none;
  margin: 0;
  padding: var(--spacing-xxs) 0;
}
.form-field-dropdown-wrapper .form-field-dropdown-li {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  color: var(--color-text-light-0);
  padding: var(--spacing-xxs) var(--spacing-sm);
}
.form-field-dropdown-wrapper .form-field-dropdown-li:hover {
  cursor: pointer;
  background-color: var(--color-interactive-light-hover);
}
.form-field-dropdown-wrapper .form-field-dropdown-li:hover .form-field-custom-checkbox {
  background-color: var(--color-interactive-light-hover);
}
.form-field-dropdown-wrapper .form-field-dropdown-li .form-field-browser-checkbox:checked ~ .form-field-custom-checkbox {
  background-color: var(--color-brand-0);
}
.form-field-dropdown-wrapper .form-field-custom-checkbox {
  border-color: var(--color-line-light);
  background-color: var(--color-background-light-0);
}
.form-field-dropdown-wrapper .other-input {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}
.svg-loading-dots circle {
  fill: var(--color-text-light-2);
  stroke: none;
  stroke-width: 1;
  fill-rule: evenodd;
}
.svg-loading-dots circle:nth-child(1) {
  animation: load 1s infinite;
}
.svg-loading-dots circle:nth-child(2) {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}
.svg-loading-dots circle:nth-child(3) {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}
@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.mic-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-xs);
}
.mic-button .mic-button-button {
  padding: var(--spacing-xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-line-light);
  border-radius: 100%;
  transition-property: box-shadow;
  transition-duration: 1000ms;
  color: var(--color-mic-button-box-shadow);
  display: flex;
}
.mic-button .mic-button-button.listening {
  transition-duration: 150ms;
}
.mic-button .mic-button-button.listening .svg-mic {
  fill: var(--color-brand-0);
}
.mic-button .mic-button-button:hover:not(:disabled) {
  opacity: 0.8;
  cursor: pointer;
}
.mic-button .mic-button-button:disabled, .mic-button .mic-button-button:disabled:hover {
  opacity: 0.5;
}
.mic-button .mic-button-button:disabled .helper-text, .mic-button .mic-button-button:disabled:hover .helper-text {
  color: var(--color-text-2);
}
.mic-button .helper-text {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
  padding: var(--spacing-xs);
  text-align: center;
  color: var(--color-text-0);
  margin-top: var(--spacing-md);
}
.mic-button.light .mic-button-button {
  color: var(--color-mic-button-box-shadow-light);
}
.mic-button.light .helper-text {
  color: var(--color-text-dark-0);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.multi-language {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xxxs);
}
.multi-language .tile {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  border: 1px solid var(--color-line);
  flex-grow: 1;
  color: var(--color-text-1);
  background-color: var(--color-background-1);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-xxxs) var(--spacing-xs);
}
.multi-language .tile .lang-native {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  line-height: 1.6;
  color: var(--color-text-2);
}
.multi-language .tile.beta {
  opacity: 0.5;
}
.multi-language::after {
  content: "";
  flex-grow: 100;
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.quick-start-link {
  border: 1px solid var(--color-line);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--color-text-0);
  border-radius: 0.25rem;
  padding: var(--spacing-xs) calc(var(--spacing-sm) + 1.25rem) var(--spacing-xs) var(--spacing-sm);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.quick-start-link_icon svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: var(--spacing-md);
}
.quick-start-link_arrow {
  position: absolute;
  right: var(--spacing-md);
  transition-property: right;
  transition-duration: 150ms;
}
.quick-start-link:hover {
  cursor: pointer;
  background-color: var(--color-interactive-hover);
}
.quick-start-link:hover .quick-start-link_arrow {
  right: var(--spacing-sm);
}
:root {
  --color-brand-0: #377dff;
  --color-brand-1: #ff3d81;
  --color-brand-2: #7429a9;
  --color-white: #fff;
  --color-black: #000;
  --color-line-light: #dee2e7;
  --color-line-dark: #3c4043;
  --color-background-light-0: #ffffff;
  --color-background-light-1: #f1f3f4;
  --color-background-dark-0: #1e2022;
  --color-background-dark-1: #181a1c;
  --color-text-light-0: #181a1c;
  --color-text-light-1: #1e2022;
  --color-text-light-2: #666666;
  --color-text-dark-0: #ffffff;
  --color-text-dark-1: #f1f3f4;
  --color-text-dark-2: #9e9e9e;
  --color-interactive-light-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-light-active: #e1f5fe;
  --color-interactive-dark-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-dark-active: rgba(55, 125, 255, 0.2);
  --color-interactive-primary: #377dff;
  --color-interactive-primary-hover: #2e69d6;
  --color-interactive-primary-active: #0a58ca;
  --color-form-error: #dc3545;
  --color-form-success: #377dff;
  --color-misc-variant-0-light: rgba(173, 234, 195, 0.1843137255);
  --color-misc-variant-1-light: rgba(160, 156, 243, 0.1333333333);
  --color-misc-variant-2-light: rgba(231, 199, 187, 0.1843137255);
  --color-misc-variant-3-light: rgba(144, 218, 217, 0.1843137255);
  --color-misc-variant-0-dark: #04802f;
  --color-misc-variant-1-dark: #804CF5;
  --color-misc-variant-2-dark: #FF6B37;
  --color-misc-variant-3-dark: #0aaab9;
  --color-demo-light: #000000;
  --color-demo-dark: #ffffff;
  --color-mic-button-box-shadow: rgba(55, 125, 255, 0.6);
  --color-mic-button-box-shadow-light: rgba(221, 236, 250, 0.6666666667);
  --color-background-0: #ffffff;
  --color-background-1: #f1f3f4;
  --color-text-0: #181a1c;
  --color-text-1: #1e2022;
  --color-text-2: #666666;
  --color-link: #276def;
  --color-interactive-hover: rgba(223, 223, 223, 0.3333333333);
  --color-interactive-active: #e1f5fe;
  --color-line: #dee2e7;
  --color-demo: #000000;
}

.dark-mode-section,
.dark-mode .docs,
[data-theme=dark] {
  --color-background-0: #1e2022;
  --color-background-1: #181a1c;
  --color-text-0: #ffffff;
  --color-text-1: #f1f3f4;
  --color-text-2: #9e9e9e;
  --color-link: #3c82ff;
  --color-interactive-hover: rgba(66, 68, 70, 0.5333333333);
  --color-interactive-active: rgba(55, 125, 255, 0.2);
  --color-line: #3c4043;
  --color-demo: #ffffff;
}

:root {
  --spacing-xxxxs: 0.125rem;
  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.375rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-md: 1rem;
  --space-unit: 1rem;
  --spacing-scalable-lg: calc(1.25 * var(--space-unit));
  --spacing-scalable-xl: calc(1.5 * var(--space-unit));
  --spacing-scalable-xxl: calc(1.75 * var(--space-unit));
  --spacing-scalable-xxxl: calc(2 * var(--space-unit));
  --spacing-scalable-xxxxl: calc(3 * var(--space-unit));
  --spacing-scalable-xxxxxl: calc(4 * var(--space-unit));
}

@media screen and (min-width: 768px) {
  :root {
    --space-unit: 1.25rem;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --space-unit: 1.5rem;
  }
}
.company-logos-carousel {
  display: flex;
  flex-direction: column;
}
.company-logos-carousel .label {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  line-height: 1.6;
  font-weight: 500;
  text-transform: uppercase;
}
@media screen and (min-width: 992px) {
  .company-logos-carousel:not(.company-logos-carousel.label-stacked) {
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 992px) {
  .company-logos-carousel:not(.company-logos-carousel.label-stacked) .label {
    display: flex;
    flex-direction: column;
    margin-right: var(--spacing-scalable-xxxl);
  }
}
.company-logos-carousel .gradient {
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 1;
}
.company-logos-carousel .gradient-left {
  background: linear-gradient(90deg, var(--color-background-0), rgba(255, 255, 255, 0));
}
.company-logos-carousel .gradient-right {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), var(--color-background-0));
}
.company-logos-carousel .logos-container {
  position: relative;
  display: flex;
  overflow: hidden;
}
.company-logos-carousel .logos-animate-container {
  min-width: 4300px;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
  display: flex;
  animation: scroll 40s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.company-logos-carousel .svg-container {
  margin: 0 var(--space-md);
}
.company-logos-carousel .svg-container svg {
  height: 2rem;
  max-width: 6rem;
}
.company-logos-carousel .svg-container .sm-svg {
  height: 3rem;
  max-width: 8rem;
}