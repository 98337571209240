// border
$util-border-radius: 0.25rem;
$util-border-radius-md: 0.5rem;
$util-border-radius-lg: 2rem;

@mixin border {
  border: 1px solid var(--color-line);
}

// transition
$util-transition-fast: 150ms;
$util-transition-default: 250ms;

// disabled
@mixin disabled {
  opacity: 0.5;
}

// layout
@mixin center-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// box shadow
@mixin box-shadow {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.125);
}