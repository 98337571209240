@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

@import "../css/palette.scss";

.rhn-expression {
  font-size: 1rem;
  font-family: $font-family-monospace;
  border-bottom: 1px solid var(--border-color);

  .new {
    padding-bottom: 1rem;
  }

  .extant {
    padding: 0.25rem 0;
    border-left: 0.125rem solid transparent;

    &.focus {
      border-left: 2px solid $pv-primary;
    }

    &.error {
      border-left: 2px solid $bootstrap-danger;
    }

    &:hover {
      background-color: var(--hover-color);

      .highlights-input-textarea {
        background-color: var(--hover-color);
      }
    }
  }

  .rhn-suggestion-list-container {
    z-index: 999;
    position: fixed;
    min-width: 8rem;
    max-height: 15rem;
    overflow-y: auto;
    border-radius: $pv-border-radius;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
  }

  .rhn-input {
    position: relative;
  }

  .rhn-input-error {
    font-size: 0.8rem;
    color: $pv-danger;

    ul {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }

  .addon-buttons {
    width: 2.5rem;
  }
}
