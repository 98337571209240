@import "../../styles";
@import "./form";

.form-field-checkbox {
  @include form-field-select-element(
    ".form-field-browser-checkbox",
    ".form-field-custom-checkbox"
  );

  &.disabled {
    @include disabled;

    &:hover {
      cursor: default;
    }
  }

  .form-field-custom-checkbox {
    border-radius: $util-border-radius;

    &::after {
      left: calc(#{4 / 16} * 1rem);
      top: calc(#{1 / 16} * 1rem);
      width: calc(#{5 / 16} * 1rem);
      height: calc(#{10 / 16} * 1rem);
      border: solid white;
      border-width: 0 calc(#{3 / 16} * 1rem) calc(#{3 / 16} * 1rem) 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.margin {
    margin-bottom: var(--spacing-scalable-lg);
  }
}
