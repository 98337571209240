.split-pane.split-pane-dynamic {
  .Resizer.vertical {
    margin: 0;
    cursor: col-resize;
    border-left-color: transparent;
  }

  .Pane.vertical.Pane1 {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}