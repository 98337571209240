@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins-Regular.eot),
  url(/fonts/Poppins-Regular.woff2) format('woff2'),
  url(/fonts/Poppins-Regular.woff) format('woff'),
  url(/fonts/Poppins-Regular.ttf) format('truetype'),
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins-Medium.eot),
  url(/fonts/Poppins-Medium.woff2) format('woff2'),
  url(/fonts/Poppins-Medium.woff) format('woff'),
  url(/fonts/Poppins-Medium.ttf) format('truetype'),
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins-SemiBold.eot),
  url(/fonts/Poppins-SemiBold.woff2) format('woff2'),
  url(/fonts/Poppins-SemiBold.woff) format('woff'),
  url(/fonts/Poppins-SemiBold.ttf) format('truetype'),
}
