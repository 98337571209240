.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-warning,
.btn.btn-danger,
.btn.btn-dark,
.btn.btn-light {
  box-shadow: none;
  background-image: none;

  &:hover {
    box-shadow: none;
    background-image: none;
  }

  &:focus {
    background-image: none;
  }
}

// Profile Password & Danger Zone buttons\
.nav-pills .nav-item .nav-link,
.nav-pills .nav-item .nav-link.active {
  background-image: none;
}

// Rhino template buttons
.btn-check:checked + .btn,
.btn-check:checked + .btn:focus,
.btn-check:checked + .btn.btn-outline-primary,
.btn-check:checked + .btn.btn-outline-primary:focus {
  outline: none;
  box-shadow: none;
  transition: none;
}