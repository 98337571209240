$pv-border-radius-md: 0.5rem;

$pv-transition-duration-1: 150ms;

$card-border-radius: $pv-border-radius-md;

$form-field-box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(13 110 253 / 25%);
$form-field-box-shadow-danger: 0 0 0 0.25rem rgb(220 53 69 / 25%);

$pv-disabled-opacity: 0.5;

$tab-height: 2.5rem;
$tab-border-bottom-width: 2px;
$tabs-scrollbar-height: 10px;