@import "./util";

@mixin sidebar-element {
  background-color: transparent;
  border-radius: $util-border-radius-md;
  padding: var(--spacing-xs) var(--spacing-md);

  &:hover {
    cursor: pointer;
    transition: background-color;
    transition-duration: $util-transition-fast;
    background-color: var(--color-interactive-hover);
  }
}