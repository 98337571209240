@import "./css/palette.scss";
@import "./css/components.scss";

.navbar {
  background-color: $pv-primary;
  border-bottom: 1px solid $pv-primary;
  height: $navbar-height;
}

.pv_logo {
  margin-left: 0.5rem;
  margin-right: 1.5rem;

  :global(img) {
    width: 2rem;
  }
}

.help_toggle {
  border: 1px solid transparent;
  padding: 0.3rem;
}

.account_toggle {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.help_toggle,
.account_toggle {
  background-color: transparent;
  border-color: transparent;

  &:hover, &:active, &:focus {
    border: 1px solid transparent;
    box-shadow: none;
    background-image: none;
    background-color: $pv-light-theme-hover;
  }

  &:focus-visible {
    box-shadow: none;
    background-image: none;
    border-color: transparent;
    background-color: transparent;
  }
}

.dropdown_link {
  white-space: nowrap;

  &:focus, &:hover {
    background-image: none;
    background-color: transparent;
  }
}

.mobile_nav {
  top: $navbar-height;
  left: 0;
  width: 311px;
  height: calc(100vh - #{$navbar-height});
  border-right: 1px solid $pv-light-theme-line;
  background-color: $pv-light-theme-background;

  transition-property: transform, visibility;
  transition-duration: $pv-transition-duration-1;
  transition-timing-function: ease-in-out;
}

.closed {
  visibility: hidden;
  transform: translateX(-100%);
}

.mobile_link {
  border: none;
  width: 100%;
  background-color: transparent;
  color: var(--color-text-0);
  text-align: left;

  &:disabled {
    color: var(--color-text-2);
  }
}

.legal {
  font-size: 0.75rem;
}

.divider {
  opacity: 1;
  background-color: $pv-light-theme-line;
}

[data-theme="dark"] {
  .navbar {
    border-bottom: 1px solid $pv-dark-theme-line;
  }

  .mobile_nav {
    border-right: 1px solid $pv-dark-theme-line;
    background-color: $pv-dark-theme-background;
  }

  .divider {
    background-color: $pv-dark-theme-line;
  }
}
