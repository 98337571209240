@import "../../css/palette";
@import "../../css/components";

.button {
  width: 100%;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color);
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &:focus {
    border: 1px solid $pv-primary;
    box-shadow: $form-field-box-shadow;
  }
}

.placeholder_button {
  color: var(--font-color-alt);
}

.dropdown {
  z-index: 99;
  padding: 0.5rem 0;
  margin-top: 0.125rem;
  width: 100%;
  position: absolute;
  max-height: 50vh;
  overflow-y: auto;
  border-radius: $pv-border-radius;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);

  &:global(.d-none) {
    display: none;
  }
}

.option {
  display: block;
  padding: 0.25rem 1rem;
  color: var(--font-color);

  &:hover {
    cursor: pointer;
    background-color: var(--hover-color);
  }
}

.header {
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--font-color-alt);
  gap: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0.375rem 0.75rem;

  &::after {
    content: "";
    display: inline-block;
    border-top: 1px solid var(--border-color);
    flex-grow: 1;
  }
}