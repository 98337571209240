@import "../css/palette";
@import "../web-ui/src/styles";

.oz_home {
  height: calc(100vh - #{$navbar-height});
  overflow-y: auto;
  scrollbar-gutter: stable;

  .title {
    @include text-h2;
    margin-bottom: var(--spacing-md);
    padding-top: var(--spacing-scalable-xxxxl);
  }

  @include breakpoint-up($breakpoint-xl) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--spacing-scalable-lg);
  }
}

.oz_home_container {
  max-width: $breakpoint-xl;
  position: relative;

  @include breakpoint-up($breakpoint-xl) {
    grid-column: 3 / 11;
  }
}

.navigation_menu {
  grid-column: 11 / 13;
  position: sticky;
  top: var(--spacing-scalable-lg);
  margin-top: var(--spacing-scalable-xxxxl);

  height: fit-content;
  flex-direction: column;
  display: none;

  @include breakpoint-up($breakpoint-xl) {
    display: flex;
  }
}

.navigation_menu_title {
  @include text-default;
  margin: 0;
}

.navigation_item {
  border: none;
  color: var(--color-text);
  background-color: transparent;
  text-align: left;
  padding: 0 var(--spacing-xxs);

  &:hover {
    color: var(--color-text-2);
  }

  &.navigation_item_active {
    color: var(--color-brand-0);
    @include text-font-weight-medium;
  }
}

.row {
  width: 100%;
}

.section_title {
  @include text-h4;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}

.animal_cards_container {
  $content-item-spacing: var(--spacing-md);
  $content-item-num-per-row-sm: 2;
  $content-item-num-per-row-lg: 3;
  $content-item-num-per-row-xxl: 4;

  display: flex;
  flex-wrap: wrap;
  gap: $content-item-spacing;

  @include breakpoint-up($breakpoint-sm) {
    > .animal_card_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-sm} - #{$content-item-spacing} * #{$content-item-num-per-row-sm -
          1} / #{$content-item-num-per-row-sm}
      );
    }
  }

  @include breakpoint-up($breakpoint-lg) {
    > .animal_card_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-lg} - #{$content-item-spacing} * #{$content-item-num-per-row-lg -
          1} / #{$content-item-num-per-row-lg}
      );
    }
  }

  @include breakpoint-up($breakpoint-xxl) {
    > .animal_card_wrapper {
      width: calc(
              100% / #{$content-item-num-per-row-xxl} - #{$content-item-spacing} * #{$content-item-num-per-row-xxl -
          1} / #{$content-item-num-per-row-xxl}
      );
    }
  }
}

.animal_card_wrapper {
  width: 100%;
}

.tutorials_container {
  $content-item-spacing: var(--spacing-md);
  display: flex;
  flex-wrap: wrap;
  gap: $content-item-spacing;
  border-radius: $util-border-radius-md;
  padding: $content-item-spacing;
  background-color: var(--color-background-1);

  .video_embed_wrapper {
    width: 100%;
    $num-per-row: 3;

    @include breakpoint-up($breakpoint-md) {
      width: calc(
              100% / #{$num-per-row} - #{$content-item-spacing} * #{$num-per-row -
          1} / #{$num-per-row}
      );
    }
  }
}

.share_my_project {
  margin-left: auto;
}

.helper_text {
  @include text-default;
  color: var(--color-text-2);
}
