@import "../../css/palette.scss";
@import "../../css/components.scss";

.card {
  border-radius: $card-border-radius;
  border: 1px solid $pv-light-theme-line;
}

.header {
  border-bottom: 1px solid $pv-light-theme-line;
}

[data-theme="dark"] {
  .card {
    border: 1px solid $pv-dark-theme-line;
  }

  .header {
    border-bottom: 1px solid $pv-dark-theme-line;
  }
}