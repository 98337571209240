@import "../web-ui/src/styles/palette";

$pv-primary: $color-brand-0;
$pv-secondary: #6c757d;
$pv-success: #00c9a7;
$pv-warning: #ffd105;
$pv-danger: $color-form-error;
$pv-offwhite: $color-bg-light-theme-1;
$pv-info: #25187e;

$pv-line: var(--color-line);
$pv-text: var(--color-text-light-0);
$pv-text-lighter: var(--color-text-light-2);

$navbar-height: 3.7rem;
$toolbar-height: 2.5rem;

$pv-border-radius: 0.25rem;

$pv-primary-dark: $pv-primary;

$bootstrap-danger: var(--color-form-error);
$bootstrap-danger-opaque: #dc354533;

$pv-white: white;
$pv-black: black;

$pv-dark-opaque: #000000CC;
$bs-form-field-box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%), 0 0 0 0.25rem rgb(13 110 253 / 25%);

$pv-pink: #FF37E1;
$pv-pink-dark: #B3279e;
$pv-pink-light: #FF73EA;

$pv-orange: #FFB937;
$pv-orange-dark: #B38227;
$pv-orange-light: #FFCE73;

$pv-green: #37FF55;
$pv-green-dark: #27B33B;
$pv-green-light: #73FF88;

$pv-dark-1: var(--color-text-light-0);
$pv-dark-2: var(--color-text-light-1);
$pv-dark-3: var(--color-text-light-2);
$pv-light-1: var(--color-text-dark-0);
$pv-light-2: var(--color-text-dark-1);
$pv-light-3: var(--color-text-dark-2);

$pv-font-dark-1: $pv-dark-1;
$pv-font-dark-2: $pv-dark-2;
$pv-font-dark-3: $pv-dark-3;
$pv-font-light-1: $pv-light-1;
$pv-font-light-2: $pv-light-2;
$pv-font-light-3: $pv-light-3;

$pv-typography--dark-1: $pv-font-dark-1;
$pv-typography--dark-2: $pv-font-dark-2;
$pv-typography--dark-3: $pv-font-dark-3;
$pv-typography--dark-4: $pv-font-dark-3;
$pv-typography--light-1: $pv-font-light-1;
$pv-typography--light-2: $pv-font-light-2;
$pv-typography--light-3: $pv-font-light-3;
$pv-typography--light-4: $pv-font-light-3;

// light theme
$pv-light-theme-text: var(--color-text-light-0);
$pv-light-theme-text-lighter: var(--color-text-light-1);
$pv-light-theme-background: var(--color-background-light-0);
$pv-light-theme-background-darker: var(--color-background-light-1);
$pv-light-theme-line: var(--color-line);
$pv-light-theme-hover: var(--color-interactive-light-hover);
$pv-light-theme-hover-alt: var(--color-interactive-light-hover);
$pv-light-theme-hover-solid: var(--color-interactive-light-hover);
$pv-light-theme-active: var(--color-interactive-light-active);
$pv-light-theme-active-hover: var(--color-interactive-light-active);
$pv-light-theme-box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
$pv-light-theme-box-shadow-secondary: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.2);
$pv-light-theme-styled-link-background: #e1f5fe;
$pv-light-theme-styled-link-background-hover: #ccefff;
$pv-light-theme-highlight-builtin: #82b3ff;
$pv-light-theme-highlight-0: #fec8d8;
$pv-light-theme-highlight-1: #adeac3;
$pv-light-theme-highlight-2: #b7d4db;
$pv-light-theme-highlight-3: #fec853;
$pv-light-theme-highlight-4: #b7cbc0;
$pv-light-theme-highlight-5: #90dad9;
$pv-light-theme-highlight-6: #a9c4dd;
$pv-light-theme-highlight-7: #f28997;
$pv-light-theme-highlight-8: #dab7b8;
$pv-light-theme-highlight-9: #f5e8c9;
$pv-light-theme-highlight-10: #e3dfa4;
$pv-light-theme-highlight-11: #ffdfd3;
$pv-light-theme-highlight-12: #957dad;
$pv-light-theme-highlight-13: #c5c7cf;
$pv-light-theme-highlight-14: #a09cf3;
$pv-light-theme-highlight-15: #e0bbe4;
$pv-light-theme-icon: #000000;
$pv-light-theme-platforms-widget: $pv-light-theme-background-darker;
$pv-light-theme-modal-header-btn-close-filter: none;

// dark theme
$pv-dark-theme-text: var(--color-text-dark-0);
$pv-dark-theme-text-darker: var(--color-text-dark-1);
$pv-dark-theme-background: var(--color-background-dark-0);
$pv-dark-theme-background-darker: var(--color-background-dark-1);
$pv-dark-theme-line: var(--color-line);
$pv-dark-theme-hover: var(--color-interactive-dark-hover);
$pv-dark-theme-hover-alt: var(--color-interactive-dark-hover);
$pv-dark-theme-hover-solid: var(--color-interactive-dark-hover);
$pv-dark-theme-active: var(--color-interactive-dark-active);
$pv-dark-theme-active-hover: var(--color-interactive-dark-active);
$pv-dark-theme-box-shadow: 0 0.375rem 1.5rem 0 rgba(0, 0, 0, 0.2);
$pv-dark-theme-box-shadow-secondary: 0.2rem 0.2rem 1rem rgba(0, 0, 0);
$pv-dark-theme-styled-link-background: #37474f;
$pv-dark-theme-styled-link-background-hover: #243a44;
$pv-dark-theme-highlight-builtin: #097BFF;
$pv-dark-theme-highlight-0: #e31e40;
$pv-dark-theme-highlight-1: #22984d;
$pv-dark-theme-highlight-2: #279fc0;
$pv-dark-theme-highlight-3: #e79c00;
$pv-dark-theme-highlight-4: #118b76;
$pv-dark-theme-highlight-5: #0aaab9;
$pv-dark-theme-highlight-6: #3797E0;
$pv-dark-theme-highlight-7: #F5494F;
$pv-dark-theme-highlight-8: #db6134;
$pv-dark-theme-highlight-9: #b39400;
$pv-dark-theme-highlight-10: #6b8b57;
$pv-dark-theme-highlight-11: #FF6B37;
$pv-dark-theme-highlight-12: #8D68AD;
$pv-dark-theme-highlight-13: #546DCC;
$pv-dark-theme-highlight-14: #804CF5;
$pv-dark-theme-highlight-15: #9734a5;
$pv-dark-theme-icon: #FFFFFF;
$pv-dark-theme-platforms-widget: $pv-dark-theme-background-darker;
$pv-dark-theme-modal-header-btn-close-filter: invert(1) grayscale(100%) brightness(200%);

:root {
  --font-color: #{$pv-light-theme-text};
  --font-color-alt: #{$pv-light-theme-text-lighter};
  --background-color: #{$pv-light-theme-background};
  --border-color: #{$pv-light-theme-line};
  --hover-color: #{$pv-light-theme-hover};
  --hover-color-alt: #{$pv-light-theme-hover-alt};
  --hover-color-solid: #{$pv-light-theme-hover-solid};
  --active-color: #{$pv-light-theme-active};
  --active-hover-color: #{$pv-light-theme-active-hover};
  --background-color-darker: #{$pv-light-theme-background-darker};
  --box-shadow: #{$pv-light-theme-box-shadow};
  --box-shadow-secondary: #{$pv-light-theme-box-shadow-secondary};
  --highlight-builtin: #{$pv-light-theme-highlight-builtin};
  --highlight-0: #{$pv-light-theme-highlight-0};
  --highlight-1: #{$pv-light-theme-highlight-1};
  --highlight-2: #{$pv-light-theme-highlight-2};
  --highlight-3: #{$pv-light-theme-highlight-3};
  --highlight-4: #{$pv-light-theme-highlight-4};
  --highlight-5: #{$pv-light-theme-highlight-5};
  --highlight-6: #{$pv-light-theme-highlight-6};
  --highlight-7: #{$pv-light-theme-highlight-7};
  --highlight-8: #{$pv-light-theme-highlight-8};
  --highlight-9: #{$pv-light-theme-highlight-9};
  --highlight-10: #{$pv-light-theme-highlight-10};
  --highlight-11: #{$pv-light-theme-highlight-11};
  --highlight-12: #{$pv-light-theme-highlight-12};
  --highlight-13: #{$pv-light-theme-highlight-13};
  --highlight-14: #{$pv-light-theme-highlight-14};
  --highlight-15: #{$pv-light-theme-highlight-15};
  --icon: #{$pv-light-theme-icon};
  --modal-header-btn-close: #{$pv-light-theme-modal-header-btn-close-filter};
}

[data-theme="dark"] {
  --font-color: #{$pv-dark-theme-text};
  --font-color-alt: #{$pv-dark-theme-text-darker};
  --background-color: #{$pv-dark-theme-background};
  --border-color: #{$pv-dark-theme-line};
  --hover-color: #{$pv-dark-theme-hover};
  --hover-color-alt: #{$pv-dark-theme-hover-alt};
  --hover-color-solid: #{$pv-dark-theme-hover-solid};
  --active-color: #{$pv-dark-theme-active};
  --active-hover-color: #{$pv-dark-theme-active-hover};
  --background-color-darker: #{$pv-dark-theme-background-darker};
  --box-shadow: #{$pv-dark-theme-box-shadow};
  --box-shadow-secondary: #{$pv-dark-theme-box-shadow-secondary};
  --highlight-builtin: #{$pv-dark-theme-highlight-builtin};
  --highlight-0: #{$pv-dark-theme-highlight-0};
  --highlight-1: #{$pv-dark-theme-highlight-1};
  --highlight-2: #{$pv-dark-theme-highlight-2};
  --highlight-3: #{$pv-dark-theme-highlight-3};
  --highlight-4: #{$pv-dark-theme-highlight-4};
  --highlight-5: #{$pv-dark-theme-highlight-5};
  --highlight-6: #{$pv-dark-theme-highlight-6};
  --highlight-7: #{$pv-dark-theme-highlight-7};
  --highlight-8: #{$pv-dark-theme-highlight-8};
  --highlight-9: #{$pv-dark-theme-highlight-9};
  --highlight-10: #{$pv-dark-theme-highlight-10};
  --highlight-11: #{$pv-dark-theme-highlight-11};
  --highlight-12: #{$pv-dark-theme-highlight-12};
  --highlight-13: #{$pv-dark-theme-highlight-13};
  --highlight-14: #{$pv-dark-theme-highlight-14};
  --highlight-15: #{$pv-dark-theme-highlight-15};
  --icon: #{$pv-dark-theme-icon};
  --modal-header-btn-close: #{$pv-dark-theme-modal-header-btn-close-filter};
}
