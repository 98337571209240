@import "../css/palette";
@import "../web-ui/src/styles";

.features-list {
  display: flex;

  .features-list-title {
    @include text-default;
    color: var(--color-text-light-1);
    margin-bottom: 0;
    transition: color $util-transition-default;
  }

  .features-list-description {
    @include text-sm;
    color: var(--color-text-light-2);
  }

  .features-list-icon {
    min-width: 1rem;
    margin: var(--spacing-xxxs) var(--spacing-sm) 0 0;

    > svg, > path:first-child {
      transition: all $util-transition-default;
      fill: var(--color-text-light-2);
    }

    > path {
      transition: all $util-transition-default;
      stroke: var(--color-text-light-2);
    }
  }

  &.blue {
    .features-list-icon {
      > svg, > path:first-child {
        fill: var(--color-brand-0);
      }

      > path {
        stroke: var(--color-brand-0);
      }
    }
  }
}
